import { NgModule } from '@angular/core';
import { Serializer, settings, slk } from 'survey-core';
// eslint-disable-next-line no-restricted-imports
import { SurveyCreatorModule } from 'survey-creator-angular';

import { configureSurveyJS } from '@pwp-common';

import { environment } from '../../../environments/config';

// Remove the button to upload a logo
Serializer.removeProperty('survey', 'logo');
configureSurveyJS(settings);
slk(environment.surveyJS.licenseKey);

@NgModule({
  exports: [SurveyCreatorModule],
})
export class SurveyJSCreatorModule {}
