import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../generic/serialization/schema-field';
import {AutomationActionSchema} from '../../generic/automation-action/automation-action-schema';

export class AutomationActionSendEmailSchema extends AutomationActionSchema {
  public static readonly body = 'body';

  public static readonly subject = 'subject';

  public static readonly Defaults = class extends AutomationActionSchema.Defaults {
    public static readonly body = undefined;
    public static readonly subject = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[AutomationActionSendEmailSchema.body] = SchemaField.string(AutomationActionSendEmailSchema.Defaults.body).required();
    parameters[AutomationActionSendEmailSchema.subject] = SchemaField.string(AutomationActionSendEmailSchema.Defaults.subject).required();

    return parameters;
  }
}
