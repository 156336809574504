<div *transloco="let unscopedT">
  <ng-container *ngIf="rowData[col.field] as cell">
    <ng-container *ngIf="cell | isRowCell; else valueCell">
      <mat-icon *ngIf="cell.icon as icon">{{ icon }}</mat-icon>

      <span class="wordWrap" [class]="cell.styleClass">{{
        unscopedT(cell.translationScope + '.' + cell.translationKey, cell.translationObj)
      }}</span>
    </ng-container>

    <ng-template #valueCell>
      <ng-container *ngIf="cell | isMoment; else rawCell">
        {{ cell | displayTimestamp }}
      </ng-container>
    </ng-template>

    <ng-template #rawCell>
      {{ cell }}
    </ng-template>
  </ng-container>
</div>
