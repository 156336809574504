import { Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { isNil } from 'lodash';

const mustMatchErrorStateMatcher: ErrorStateMatcher = {
  isErrorState(control: FormControl | null): boolean {
    if (isNil(control)) {
      return false;
    }

    const invalid = control.invalid || control.parent?.errors?.mustMatch;

    return control?.touched && invalid;
  },
};

@Directive({
  selector: '[appMustMatchErrorStateMatcher]',
  standalone: true,
  providers: [{ provide: ErrorStateMatcher, useValue: mustMatchErrorStateMatcher }],
})
export class MustMatchErrorStateMatcherDirective {}
