import { translate } from '@ngneat/transloco';

import { DBDocObject, GenericDisplayable } from '@pwp-common';

import { AutocompleteGroup } from './interfaces';

export const createAutocompleteGroup = (items: GenericDisplayable[], builder: typeof DBDocObject): AutocompleteGroup => {
  const collectionName = builder.getSchema().getCollectionName();
  const icons = {
    callLists: 'call',
    communicationWorkflows: 'list',
    conversationConfig: 'chat',
  };

  return {
    label: translate(`automation-trigger-autocomplete.group.${collectionName}`),
    value: collectionName,
    items: items.map((item) => ({
      icon: icons[collectionName],
      label: item.getDisplayName(),
      value: builder.getSchema().getOrgScopedPath(item.getId()),
    })),
  };
};
