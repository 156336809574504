import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, UrlTree } from '@angular/router';
import { isNil } from 'lodash';

import { AuthenticationService } from '../../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
  private readonly authenticationService = inject(AuthenticationService);

  private readonly router = inject(Router);

  private getErrorUrlTree(queryParams: Params): UrlTree {
    return this.router.createUrlTree(['/auth/reset-password-error'], { queryParams });
  }

  public async canActivate(activatedRoute: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const { queryParams } = activatedRoute;
    const { oobCode } = queryParams;

    if (isNil(oobCode)) {
      return this.getErrorUrlTree(queryParams);
    }

    try {
      const userEmail = await this.authenticationService.verifyPasswordResetCode(oobCode);

      if (userEmail === queryParams.userEmail) {
        return true;
      }

      return this.router.createUrlTree(['/auth/reset-password'], {
        queryParams: { ...queryParams, userEmail },
      });
    } catch (error) {
      return this.getErrorUrlTree(queryParams);
    }
  }
}
