<nav mat-tab-nav-bar backgroundColor="primary">
  <!-- Toggle Side Navigation Panel -->
  <button *appIfRole="'loggedIn'" mat-icon-button (click)="sideNavigation.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <ng-container *transloco="let t">
    <a *appIfRole="'loggedIn'" #rla="routerLinkActive" mat-tab-link routerLink="/events-calendar" routerLinkActive [active]="rla.isActive">
      {{ t('eventsCalendar') }}
    </a>

    <a *appIfRole="'loggedIn'" #rla="routerLinkActive" mat-tab-link routerLink="/user-profile" routerLinkActive [active]="rla.isActive">
      {{ t('userProfile') }}
    </a>
  </ng-container>

  <!-- Language Picker -->
  <button mat-flat-button color="primary" [matMenuTriggerFor]="menu">
    {{ getLanguageString(selectedLanguage) }}
    <mat-icon class="example-icon">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="setLanguage('en')">English</button>
    <button mat-menu-item (click)="setLanguage('es')">Español</button>
  </mat-menu>

  <!-- Logout Button -->
  <ng-container *transloco="let t">
    <a
      *appIfRole="'loggedIn'"
      #rla="routerLinkActive"
      mat-tab-link
      class="ml-auto"
      routerLink="/logout"
      routerLinkActive
      [active]="rla.isActive"
    >
      {{ t('logout') }}
    </a>
  </ng-container>
</nav>

<mat-sidenav-container color="primary">
  <!-- Side Navigation Panel -->
  <mat-sidenav #sideNavigation [fixedInViewport]="mobileQuery.matches" [fixedTopGap]="48" [mode]="mobileQuery.matches ? 'over' : 'side'">
    <ng-container *transloco="let t">
      <!-- Side Nagivation Title -->
      <mat-toolbar>
        <mat-toolbar-row>
          <h2>{{ t('welcome') }} {{ displayName }}!</h2>
        </mat-toolbar-row>
      </mat-toolbar>

      <mat-nav-list>
        <!-- Conversation Tabs -->
        <div *appIfRole="'conversation'">
          <!-- Conversation -->
          <a mat-list-item routerLink="/chat" (click)="sideNavigation.close()">
            <mat-icon matListIcon> chat_bubble </mat-icon>
            {{ t('conversation') }}
          </a>
        </div>

        <!-- Voicemails and callback requests -->
        <div *appIfRole="'voicemail'">
          <a mat-list-item routerLink="/inbox" (click)="sideNavigation.close()">
            <mat-icon matListIcon> voicemail </mat-icon>
            {{ t('voicemailsAndCallbackRequests') }}
          </a>
        </div>

        <!-- Support -->
        <a mat-list-item routerLink="/support" (click)="sideNavigation.close()">
          <mat-icon matListIcon> help </mat-icon>
          {{ t('support') }}
        </a>
      </mat-nav-list>

      <!-- Admin Tabs -->
      <div *ngIf="(roles$ | async)?.orgAdmin; else basicAnalyticsOptions">
        <mat-nav-list>
          <h2 matSubheader>{{ t('adminstrativeOptions') }}</h2>

          <!-- Shift Requests -->
          <a mat-list-item routerLink="/event-requests" (click)="sideNavigation.close()">
            <mat-icon matListIcon> reply </mat-icon>
            {{ t('eventRequests') }}
          </a>

          <!-- Modify Schedule -->
          <a mat-list-item routerLink="/modify-schedule" (click)="sideNavigation.close()">
            <mat-icon matListIcon> edit </mat-icon>
            {{ t('modifySchedule') }}
          </a>

          <!-- Users -->
          <a mat-list-item routerLink="/users" (click)="sideNavigation.close()">
            <mat-icon matListIcon> people </mat-icon>
            {{ t('users') }}
          </a>

          <!-- Service Exceptions -->
          <a mat-list-item routerLink="/serviceExceptions" (click)="sideNavigation.close()">
            <mat-icon matListIcon> folder_special </mat-icon>
            {{ t('serviceExceptions') }}
          </a>

          <!-- Settings -->
          <a mat-list-item routerLink="/orgSettings" (click)="sideNavigation.close()">
            <mat-icon matListIcon> settings </mat-icon>
            {{ t('orgSettings') }}
          </a>
        </mat-nav-list>

        <!-- Analytics -->
        <mat-nav-list>
          <h2 matSubheader>{{ t('analytics') }}</h2>
          <!-- Call Logs Button -->
          <a mat-list-item routerLink="/call-logs" (click)="sideNavigation.close()">
            <mat-icon matListIcon> history </mat-icon>
            {{ t('callLogs') }}
          </a>

          <!-- User Load -->
          <a mat-list-item routerLink="/stats" (click)="sideNavigation.close()">
            <mat-icon matListIcon> bar_chart </mat-icon>
            {{ t('stats') }}
          </a>
        </mat-nav-list>
      </div>

      <ng-template #basicAnalyticsOptions>
        <mat-nav-list>
          <h2 matSubheader>{{ t('analytics') }}</h2>
          <!-- Call Logs Button -->
          <a mat-list-item routerLink="/call-logs" (click)="sideNavigation.close()">
            <mat-icon matListIcon> history </mat-icon>
            {{ t('callLogs') }}
          </a>
        </mat-nav-list>
      </ng-template>

      <!-- Configuration Tabs -->
      <div *appIfRole="'pwpAdmin'">
        <mat-nav-list>
          <h2 matSubheader>Configuration Options</h2>

          <!-- Org Data -->
          <a mat-list-item routerLink="/org-select-and-edit" (click)="sideNavigation.close()">
            <mat-icon matListIcon> business </mat-icon>
            Org Editor
          </a>
        </mat-nav-list>
      </div>

      <mat-nav-list>
        <h2 matSubheader>{{ t('general') }}</h2>
        <!-- Logout Button -->
        <a mat-list-item routerLink="/logout" (click)="sideNavigation.close()">
          <mat-icon matListIcon> exit_to_app </mat-icon>
          {{ t('logout') }}
        </a>
      </mat-nav-list>
    </ng-container>
  </mat-sidenav>

  <!-- Main Content -->
  <mat-sidenav-content class="app__content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
