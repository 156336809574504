import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Params } from '@angular/router';
import { DialogService } from '@ngneat/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Sentry from '@sentry/angular';
import { isNil } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { BehaviorSubject, combineLatest, defer, filter, from, map, of, share, switchMap, take } from 'rxjs';

import {
  ConversationAttributes,
  ConversationUserIdentityType,
  ExternalChatResponse,
  ExternalChatResponseError,
  SupportedLanguages,
  TemplateText,
  TwilioConversationState,
  WebchatActionType,
} from '@pwp-common';

import { Message, Room } from '../../common/conversation/chat-component/interfaces';
import { ConversationClient } from '../../common/conversation/conversation-client/conversation-client/conversation-client';
import { ConversationClientDisplay } from '../../common/conversation/conversation-client-display/conversation-client-display';
import { quickExit } from '../../common/conversation/quick-exit/quick-exit';
import { sendWebchatAction } from '../../common/conversation/send-webchat-action/send-webchat-action';
import { isInIframe } from '../../common/global/is-in-iframe/is-in-iframe';
import { ShadowRootListenerDirective } from '../../common/ui/shadow-root-listener/shadow-root-listener.directive';
import { ShadowRootStylesDirective } from '../../common/ui/shadow-root-styles/shadow-root-styles.directive';
import { ConversationEndedDialogComponent } from '../../components/conversation/incoming-chat/conversation-ended-dialog/conversation-ended-dialog.component';
import { IncomingChatWaitingRoomComponent } from '../../components/conversation/incoming-chat/incoming-chat-waiting-room/incoming-chat-waiting-room.component';
import { ExternalChatEndpointService } from '../../services/conversation/external-chat-endpoint/external-chat-endpoint.service';

const BOT_FILTERING_OPTED_IN_ORGS = [
  // Prod Health Check
  '05JjofNjOcRCunQkTVtV',
  // BARCC
  'ty7vDEaWc5UxRMlMEF4W',
  // CCSS
  'o75GGwJS9hxlGDWt4UvL',
  // VIP
  'zecchXslWSXJu3a98lnH',
  // Testing org
  'S6E04dqIvOg0ljfAeLgr',
];

@UntilDestroy()
@Component({
  selector: 'app-incoming-chat',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DialogModule,
    MatProgressSpinnerModule,
    ShadowRootListenerDirective,
    ShadowRootStylesDirective,
    TranslocoModule,
  ],
  templateUrl: './incoming-chat.component.html',
  styleUrls: ['./incoming-chat.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IncomingChatComponent implements OnInit, OnDestroy {
  private orgId = this.activatedRoute.snapshot.queryParams.orgId;

  private readonly botDetected$ = from(defer(() => import('@fingerprintjs/botd'))).pipe(
    switchMap(({ load }) => load()),
    map((botd) => botd.detect().bot),
  );

  private readonly initializationConfirmed = new BehaviorSubject(false);

  ////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////
  // eslint-disable-next-line
  __testing__clientOverride: ConversationClientDisplay;

  client: ConversationClientDisplay;

  loading = true;

  messagesLoaded = false;

  rooms: Room[] = [];

  messages: Message[] = [];

  redirectURL = 'https://www.google.com';

  showFooter = true;

  dialogOpen = false;

  waitingRoomDialog: MatDialogRef<IncomingChatWaitingRoomComponent>;

  public readonly readyForInitialization$ = BOT_FILTERING_OPTED_IN_ORGS.includes(this.orgId)
    ? combineLatest([this.botDetected$, this.initializationConfirmed]).pipe(
        map(([botDetected, initializationConfirmed]) => !botDetected || initializationConfirmed),
        share(),
      )
    : of(true);

  public readonly participantDisplayId = ConversationUserIdentityType.anonymousExternalId;

  public readonly messages$ = defer(() => this.client?.displayableMessages$ ?? of([]));

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private externalChatEndpointService: ExternalChatEndpointService,
    private ngNeatDialogService: DialogService,
    private translocoService: TranslocoService,
  ) {}

  public ngOnInit(): void {
    if (isInIframe()) {
      sendWebchatAction({ type: WebchatActionType.chatLoaded });
    }

    this.readyForInitialization$
      .pipe(
        filter((ready) => ready === true),
        take(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.subscribeURLChanges();
      });

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  async ngOnDestroy() {
    await this.client?.shutdown();
  }

  ////////////////////////////////////////////////////////////////////////
  // Parse URL
  ////////////////////////////////////////////////////////////////////////

  private subscribeURLChanges() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
      await this.parseURL(params);
      if (isNil(this.orgId)) {
        await this.showUnauthorizedDialog();
        return;
      }
      await this.initChat();
    });
  }

  private async parseURL(params: Params) {
    console.log('IncomingChatComponent.parseURL: Starting.');

    if (isNil(params) || isNil(params.orgId)) {
      console.log('IncomingChatComponent.parseURL: Nothing to do.');
      return;
    }

    console.log('IncomingChatComponent.parseURL: Got params');
    this.orgId = params.orgId;
  }

  ////////////////////////////////////////////////////////////////////////
  // Init Chat
  ////////////////////////////////////////////////////////////////////////

  async initChat() {
    console.log(`IncomingChatComponent.initChat`);
    Sentry.addBreadcrumb({
      category: 'IncomingChatComponent / initChat',
      message: 'Starting',
      data: { orgId: this.orgId },
    });

    let response: ExternalChatResponse;
    try {
      Sentry.addBreadcrumb({
        category: 'IncomingChatComponent / initChat',
        message: 'Getting new chat JWT',
        data: { orgId: this.orgId },
      });
      response = await this.externalChatEndpointService.getNewAnonymousChatJwt(this.orgId);
      if (!isNil(response.getError())) {
        Sentry.addBreadcrumb({
          category: 'IncomingChatComponent / initChat',
          message: 'Error response',
          data: { orgId: this.orgId, error: response.getError() },
        });
        await this.handleInitError(undefined, response);

        return;
      }
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'IncomingChatComponent / initChat',
        message: 'Unexpected error',
        data: { orgId: this.orgId, error: response?.getError() },
      });
      await this.handleInitError(error, response);
      return;
    }

    try {
      this.client = this.__testing__clientOverride;
      if (this.__testing__clientOverride === undefined) {
        const conversationClient = new ConversationClient({ jwt: response.getJwt()! });
        this.client = new ConversationClientDisplay({ client: conversationClient });
      }
      this.client.$messagesLoaded.pipe(untilDestroyed(this)).subscribe((messagesLoaded) => {
        this.messagesLoaded = messagesLoaded;
        this.changeDetectorRef.detectChanges();
      });
      this.client.$state.pipe(untilDestroyed(this)).subscribe((state) => {
        if (state?.state === TwilioConversationState.closed) {
          this.showFooter = false;
          this.showConversationEndedDialog();
          this.changeDetectorRef.detectChanges();
        }
      });
      this.client.$room.pipe(untilDestroyed(this)).subscribe((room) => {
        if (room === undefined) {
          return;
        }
        this.rooms = [room];
        this.redirectURL = room.attributes?.getRedirectURL() ?? this.redirectURL;
        this.showWaitingRoomIfNecessary(room.attributes);
        this.changeDetectorRef.detectChanges();
      });
      this.client.$authExpired.pipe(untilDestroyed(this)).subscribe((expired) => {
        if (expired === true) {
          this.showConversationEndedDialog();
        }
      });
      await this.client.setSelectedConversation(response.getConversationSid());
    } catch (error) {
      console.error(error);
      await this.showUnexpectedError(JSON.stringify(error));
    } finally {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Send Message
  ///////////////////////////////////////////////////////////////////////

  sendMessage(message: string) {
    this.client?.sendMessage(message);
  }

  ///////////////////////////////////////////////////////////////////////
  // Handle Error
  ///////////////////////////////////////////////////////////////////////

  private async handleInitError(endpointTriggerError?: { code: string }, response?: ExternalChatResponse) {
    console.error('IncomingChatComponent.handleInitError', { endpointTriggerError, response });

    Sentry.addBreadcrumb({
      category: 'IncomingChatComponent / handleInitError',
      message: 'Starting',
      level: 'warning',
      data: { orgId: this.orgId, endpointTriggerError, response },
    });

    if (!isNil(endpointTriggerError?.code)) {
      switch (endpointTriggerError.code) {
        case 'functions/permission-denied': {
          await this.showUnauthorizedDialog();
          break;
        }
        case 'functions/internal': {
          await this.showConnectivityError();
          break;
        }
        default: {
          await this.showUnexpectedError(endpointTriggerError.code);
          break;
        }
      }
    }

    switch (response?.getError()) {
      case ExternalChatResponseError.cannotCreateConversation: {
        await this.showUnexpectedError('cannotCreateConversation');
        break;
      }
      case ExternalChatResponseError.notConfigured: {
        await this.showUnexpectedError('notConfigured');
        break;
      }
      case ExternalChatResponseError.serviceClosed: {
        await this.showServiceUnavailable(response.getMessage());
        break;
      }
      default: {
        break;
      }
    }
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ////////////////////////////////////////////////////////////////////////
  // Quick Exit
  ////////////////////////////////////////////////////////////////////////

  public quickExit(): void {
    quickExit(this.redirectURL);
  }

  ///////////////////////////////////////////////////////////////////////
  // Waiting Room
  ///////////////////////////////////////////////////////////////////////

  showWaitingRoomIfNecessary(conversationAttributes: ConversationAttributes) {
    const queuePosition = conversationAttributes?.getWaitingRoom()?.getQueuePosition();
    if (isNil(queuePosition) || queuePosition === 0) {
      this.waitingRoomDialog?.close();
      return;
    }

    if (isNil(this.waitingRoomDialog)) {
      this.waitingRoomDialog = this.dialog.open(IncomingChatWaitingRoomComponent, {
        hasBackdrop: true,
        minWidth: '30%',
        data: {
          client: this.client,
        },
        disableClose: true,
      });
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Dialogs
  ///////////////////////////////////////////////////////////////////////

  showConversationEndedDialog() {
    if (this.dialogOpen) {
      return;
    }
    this.dialogOpen = true;
    this.dialog.open(ConversationEndedDialogComponent, {
      hasBackdrop: true,
      minWidth: '30%',
      data: {
        redirectURL: this.redirectURL,
      },
    });
  }

  async showServiceUnavailable(message: TemplateText | undefined) {
    const title = await this.translocoService.selectTranslate('incoming-chat.serviceUnavailableTitle').pipe(take(1)).toPromise();

    const bodyDefault = await this.translocoService.selectTranslate('incoming-chat.serviceUnavailableBody').pipe(take(1)).toPromise();

    const body = message?.getText().get(SupportedLanguages.en.getShortCode()) ?? bodyDefault;
    this.ngNeatDialogService.error({
      title,
      body,
    });
  }

  async showApiMessage(body: string) {
    this.ngNeatDialogService.error({
      title: '',
      body,
    });
  }

  async showConnectivityError() {
    const title = await this.translocoService.selectTranslate('pwp-api.errorTimeoutTitle').pipe(take(1)).toPromise();
    const body = await this.translocoService.selectTranslate('pwp-api.errorTimeoutBody').pipe(take(1)).toPromise();

    this.ngNeatDialogService.error({
      title,
      body,
    });
  }

  async showUnauthorizedDialog() {
    const title = await this.translocoService.selectTranslate('incoming-chat.unauthorizedDialogTitle').pipe(take(1)).toPromise();
    const body = await this.translocoService.selectTranslate('incoming-chat.unauthorizedDialogBody').pipe(take(1)).toPromise();

    this.ngNeatDialogService.error({
      title,
      body,
    });
  }

  async showUnexpectedError(message: string) {
    const title = await this.translocoService.selectTranslate('incoming-chat.unexpectedErrorDialogTitle').pipe(take(1)).toPromise();
    const body = await this.translocoService
      .selectTranslate('incoming-chat.unexpectedErrorDialogBody', { message })
      .pipe(take(1))
      .toPromise();

    this.ngNeatDialogService.error({
      title,
      body,
    });
  }

  public confirmInitialization(): void {
    this.initializationConfirmed.next(true);
  }
}
