import { cloneDeep, isNil } from 'lodash';

import { CallList } from '@pwp-common';

import { answerQuicklyRingTimeEditorOutput, defaultOfferConfigRingTimeEditorOutput } from '../default-offer-config-ring-time-editor-output';
import { OfferConfigRingTimeEditorOutput } from '../interfaces';
import { PresetValues } from '../preset-values';

export const makeCallListOfferConfigRingTimeEditorOutput = (callList: CallList): OfferConfigRingTimeEditorOutput | undefined => {
  if (isNil(callList)) {
    return cloneDeep(defaultOfferConfigRingTimeEditorOutput);
  }
  const ringTimeoutSeconds = callList.getDialTimeoutSeconds();
  const hangUpOnVoiceMail =
    callList.getOfferConfig()?.getOfferChannelConfigPhoneCall()?.getMachineDetection()?.getHangupOnMachine() ??
    defaultOfferConfigRingTimeEditorOutput.hangUpOnVoiceMail;

  const preset =
    ringTimeoutSeconds === answerQuicklyRingTimeEditorOutput.ringTimeoutSeconds && hangUpOnVoiceMail
      ? PresetValues.answerQuickly
      : PresetValues.custom;
  return {
    ringTimeoutSeconds,
    hangUpOnVoiceMail,
    preset,
  };
};
