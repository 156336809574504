import moment from 'moment-timezone';

import {GLOBAL_TIMEZONE} from '../../constants';

import {DisplayTimeOptions} from './interfaces';

export const displayTime = (time?: moment.Moment | Date, timezone: string = GLOBAL_TIMEZONE, options?: DisplayTimeOptions): string => {
  if (time === undefined) {
    return '';
  }

  if (options?.dateOnly === true) {
    return moment.tz(time, timezone).format('L');
  }

  let timeSuffix = '';
  if (options?.showSeconds === true) {
    timeSuffix = ':ss';
  }

  if (options?.timeOnly === true) {
    return moment.tz(time, timezone).format(`hh:mm${timeSuffix} A`);
  } else {
    return moment.tz(time, timezone).format(`ddd MMM D, h:mm${timeSuffix} A z`);
  }
};
