import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../../../services/user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private readonly authService = inject(AuthService);

  private readonly router = inject(Router);

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const returnURL = state.url;

    return this.authService.isLoggedIn().pipe(
      map((isLoggedIn) =>
        isLoggedIn
          ? true
          : this.router.createUrlTree(['/auth/login'], {
              queryParams: { returnURL },
            }),
      ),
    );
  }
}
