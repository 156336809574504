import {AsyncServiceRequestSchema} from '../../generic/async-service-request/async-service-request-schema';

export class AsyncServiceRequestE164PhoneSchema extends AsyncServiceRequestSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class extends AsyncServiceRequestSchema.Defaults {};

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  /**
   * Schema for this object is encoded into a command name, and in parameters
   */

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    /* eslint-enable */

    return parameters;
  }
}
