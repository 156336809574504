import { Pipe, PipeTransform } from '@angular/core';

import { DBDocObject } from '@pwp-common';

import { AuditEntry } from './interfaces';

@Pipe({
  name: 'auditEntry',
  standalone: true,
})
export class AuditEntryPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  public transform(obj: unknown): AuditEntry {
    if (!(obj instanceof DBDocObject)) {
      return null;
    }

    return {
      timestamp: obj.getLastUploadTime(),
      userId: obj.getLastModifiedByUserId(),
    };
  }
}
