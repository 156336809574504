import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../generic/serialization/schema-field';
import {AutomationSchema} from '../../generic/automation/automation-schema';

export class AutomationOnCommunicationCompleteSchema extends AutomationSchema {
  public static readonly formId = 'formId';

  public static readonly Defaults = class extends AutomationSchema.Defaults {
    public static readonly formId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[AutomationOnCommunicationCompleteSchema.formId] = SchemaField.autoGeneratedDocId(AutomationOnCommunicationCompleteSchema.Defaults.formId).optional();

    return parameters;
  }
}
