import {GenericDisplayableSchema} from '../../../generic/displayable/generic-displayable-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';
import {AnyVoiceResponseCommand} from '../../../voice-response-command/any-voice-response-command/any-voice-response-command';
import type {Hangup} from '../../../voice-response-command/hangup/hangup';
import type {Say} from '../../../voice-response-command/say/say';
import {CalleeEntity} from '../../callee-entity/callee-entity';
import {CallListExtensionConfig} from '../extension-config/call-list-extension-config/call-list-extension-config';
import {CallListOfferConfig} from '../offer-config/call-list-offer-config/call-list-offer-config';

import {defaultCallListOfferConfig, defaultOnNoUserLeftToDialModIncCallCommands} from './constants';

/**
 * The user that answers a call is determined as the next one in a list. Such
 * a list can be generated from a combination of the users signed up from a
 * given calendar, and fixed sets of users.
 *
 * These lists have capacity, and each entry can have have a blockout time.
 * This data structure encodes such data.
 *
 * It is a more sophisticated version of this:
 * https://www.twilio.com/labs/twimlets/findme
 */
export class CallListSchema extends GenericDisplayableSchema {
  // List of objects specifying who to call, in which order
  public static readonly calleeEntities = 'calleeEntities';

  // Maximum number of simultaneous calls for this call list
  public static readonly capacity = 'capacity';

  // IVR that counselor is directed to when they pick up a call
  public static readonly confirmJoinIVRId = 'confirmJoinIVRId';

  // This array of commands specifies what do to the incoming call when there is no user left to dial
  public static readonly onNoUserLeftToDialModIncCallCommands = 'onNoUserLeftToDialModIncCallCommands';

  // This array of commands specifies commands to execute if the caller presses * to hangup
  public static readonly callerHangupOnStarCommands = 'callerHangupOnStarCommands';

  // This array of commands specifies commands to execute if the called party presses * to hangup
  public static readonly calleeHangupOnStarCommands = 'calleeHangupOnStarCommands';

  // This array of commands specifies commands to execute if the called party presses * to hangup
  public static readonly dialTimeoutSeconds = 'dialTimeoutSeconds';

  // Retention Duration
  public static readonly pIIRetentionDuration = 'pIIRetentionDuration';

  //offerConfig
  public static readonly offerConfig = 'offerConfig';

  //extensionConfig
  public static readonly extensionConfig = 'extensionConfig';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'callLists';
  };

  static Defaults = class {
    public static readonly calleeEntities = [];
    public static readonly capacity = 10;
    public static readonly confirmJoinIVRId = 'missing-confirmJoinIVRId';
    public static readonly description = 'missing-description';
    public static readonly displayName = 'missing-displayName';
    public static readonly onNoUserLeftToDialModIncCallCommands: (Say | Hangup)[] = defaultOnNoUserLeftToDialModIncCallCommands;
    public static readonly callerHangupOnStarCommands = [];
    public static readonly calleeHangupOnStarCommands = [];
    public static readonly dialTimeoutSeconds = 60;
    public static readonly pIIRetentionDuration = 'P7D';
    public static readonly offerConfig = defaultCallListOfferConfig;
    public static readonly extensionConfig = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([CallListSchema.callerHangupOnStarCommands, CallListSchema.calleeHangupOnStarCommands, CallListSchema.offerConfig]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CallListSchema.calleeEntities] = SchemaField.shortObjArray(CalleeEntity, CallListSchema.Defaults.calleeEntities);
    parameters[CallListSchema.capacity] = SchemaField.number(CallListSchema.Defaults.capacity);
    parameters[CallListSchema.confirmJoinIVRId] = SchemaField.string(CallListSchema.Defaults.confirmJoinIVRId);
    parameters[CallListSchema.onNoUserLeftToDialModIncCallCommands] = SchemaField.shortObjArray(AnyVoiceResponseCommand, CallListSchema.Defaults.onNoUserLeftToDialModIncCallCommands);
    parameters[CallListSchema.callerHangupOnStarCommands] = SchemaField.shortObjArray(AnyVoiceResponseCommand, CallListSchema.Defaults.callerHangupOnStarCommands);
    parameters[CallListSchema.calleeHangupOnStarCommands] = SchemaField.shortObjArray(AnyVoiceResponseCommand, CallListSchema.Defaults.calleeHangupOnStarCommands);
    parameters[CallListSchema.dialTimeoutSeconds] = SchemaField.number(CallListSchema.Defaults.dialTimeoutSeconds);
    parameters[CallListSchema.pIIRetentionDuration] = SchemaField.isoDuration(CallListSchema.Defaults.pIIRetentionDuration);
    parameters[CallListSchema.offerConfig] = SchemaField.obj(CallListOfferConfig, CallListSchema.Defaults.offerConfig).optional();
    parameters[CallListSchema.extensionConfig] = SchemaField.obj(CallListExtensionConfig, CallListSchema.Defaults.extensionConfig).optional();
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${CallListSchema.Constants.collection}`;
  }
}
