import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'isMoment',
  standalone: true,
  pure: true,
})
export class IsMomentPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  public transform(value: unknown): value is moment.Moment {
    return moment.isMoment(value);
  }
}
