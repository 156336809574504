import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import wrap from 'word-wrap';

import { CallStatsFunnel, EntityStats, EntityStatsDisplayWindow } from '@pwp-common';

@UntilDestroy()
@Component({
  selector: 'app-calls-by-status',
  templateUrl: './calls-by-status.component.html',
  styleUrls: ['./calls-by-status.component.css'],
})
export class CallsByStatusComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() orgStats: EntityStats;

  @Input() window: EntityStatsDisplayWindow;

  parents: string[] = [];

  labels: string[] = [];

  values: number[] = [];

  text: string[] = [];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private translocoService: TranslocoService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe((lang: string) => {
      this.refreshData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('orgStats' in changes || 'window' in changes) {
      this.refreshData();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  private refreshData() {
    this.resetData();
    if (isNil(this.orgStats) || isNil(this.window)) {
      return;
    }

    // @Todo:
    /**
     * Much of this is commented out because there is overlap some answered calls are also blocked. We should also
     * track the number of new blocked callers created.
     *
     * Note that a call can be connected to the user and blocked.
     */

    const callStatsFunnel = new CallStatsFunnel(this.orgStats, this.window);
    this.labels = [
      this.translocoService.translate('call-status-by-component.title_received'),
      this.translocoService.translate('call-status-by-component.title_no_requested_service'),
      this.translocoService.translate('call-status-by-component.title_requested_service'),
      this.translocoService.translate('call-status-by-component.title_did_not_attempt_to_connect'),
      this.translocoService.translate('call-status-by-component.title_no_capacity'),
      this.translocoService.translate('call-status-by-component.title_blocked'),
      this.translocoService.translate('call-status-by-component.title_attempted_to_connect'),
      // this.translocoService.translate('call-status-by-component.title_answered'),
      // this.translocoService.translate('call-status-by-component.title_unanswered'),
      // this.translocoService.translate('call-status-by-component.title_client_connected'),
      // this.translocoService.translate('call-status-by-component.title_unexpectedly_missed'),
      // this.translocoService.translate('call-status-by-component.title_client_disconnected_early_user_answered'),
      // this.translocoService.translate('call-status-by-component.title_client_disconnected_early_user_not_answered'),
    ];
    this.parents = [
      '',
      this.translocoService.translate('call-status-by-component.title_received'),
      this.translocoService.translate('call-status-by-component.title_received'),
      this.translocoService.translate('call-status-by-component.title_requested_service'),
      this.translocoService.translate('call-status-by-component.title_did_not_attempt_to_connect'),
      this.translocoService.translate('call-status-by-component.title_did_not_attempt_to_connect'),
      this.translocoService.translate('call-status-by-component.title_requested_service'),
      // this.translocoService.translate('call-status-by-component.title_attempted_to_connect'),
      // this.translocoService.translate('call-status-by-component.title_attempted_to_connect'),
      // this.translocoService.translate('call-status-by-component.title_answered'),
      // this.translocoService.translate('call-status-by-component.title_unanswered'),
      // this.translocoService.translate('call-status-by-component.title_answered'),
      // this.translocoService.translate('call-status-by-component.title_unanswered'),
    ];
    this.values = [
      callStatsFunnel.iNum,
      callStatsFunnel.iNumServiceNotRequested,
      callStatsFunnel.iNumServiceRequested,
      callStatsFunnel.iNumNoCapacity + callStatsFunnel.iNumEnforceBlockedCaller,
      callStatsFunnel.iNumNoCapacity,
      callStatsFunnel.iNumEnforceBlockedCaller,
      callStatsFunnel.iNumUnblockedAndHaveCapacity,
      // callStatsFunnel.iNumAnswered,
      // callStatsFunnel.iNumUnblockedAndHaveCapacity - callStatsFunnel.iNumAnswered,
      // callStatsFunnel.iNumClientConnectedToUser,
      // callStatsFunnel.iNumUnexpectedlyMissed,
      // callStatsFunnel.answeredButNotConnectedToClient,
      // callStatsFunnel.iNumUnanswered - callStatsFunnel.iNumUnexpectedlyMissed,
    ];
    this.text = [
      this.translocoService.translate('call-status-by-component.desc_received'),
      this.translocoService.translate('call-status-by-component.desc_no_requested_service'),
      this.translocoService.translate('call-status-by-component.desc_requested_service'),
      this.translocoService.translate('call-status-by-component.desc_did_not_attempt_to_connect'),
      this.translocoService.translate('call-status-by-component.desc_no_capacity'),
      this.translocoService.translate('call-status-by-component.desc_blocked'),
      this.translocoService.translate('call-status-by-component.desc_attempted_to_connect'),
      // this.translocoService.translate('call-status-by-component.desc_answered'),
      // this.translocoService.translate('call-status-by-component.desc_unanswered'),
      // this.translocoService.translate('call-status-by-component.desc_client_connected'),
      // this.translocoService.translate('call-status-by-component.desc_unexpectedly_missed'),
      // this.translocoService.translate('call-status-by-component.desc_client_disconnected_early_user_answered'),
      // this.translocoService.translate('call-status-by-component.desc_client_disconnected_early_user_not_answered'),
    ].map((z) => wrap(z, { newline: '<br>', trim: true, indent: '' }));

    this.changeDetectorRef.detectChanges();
  }

  private resetData() {
    this.labels = [];
    this.parents = [];
    this.values = [];
  }
}
