import { translate } from '@ngneat/transloco';

import { AutomationTableRow, CreateAutomationTableRowInput } from './interface';

export const createAutomationTableRow = ({
  automation,
  callLists,
  communicationWorkflows,
  conversationConfigs,
}: CreateAutomationTableRowInput): AutomationTableRow => {
  const collections = { callLists, communicationWorkflows, conversationConfigs };

  const triggerOn = automation
    .getTriggerOn()
    .getOrgScopedPaths()
    .map((path) => {
      const [collectionName, id] = path.split('/');

      return collections[collectionName]?.get(id)?.getDisplayName() ?? '';
    })
    .join(', ');

  const summary = automation
    .getActions()
    .map((action) => translate(`automation-table-editor.actions.${action.getType()}`))
    .join(', ');

  return {
    id: automation.getId(),
    automation,
    displayName: automation.getDisplayName(),
    description: automation.getDescription(),
    triggerOn,
    summary,
  };
};
