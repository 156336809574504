import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AllDataUser, EventData, EventType, OrgData } from '@pwp-common';

import { OrgDataService } from '../../services/orgs/org-data/org-data.service';
import { AllDataUserService } from '../../services/user/all-data-user/all-data-user.service';

@Component({
  selector: 'app-modify-schedule',
  templateUrl: './modify-schedule.component.html',
  styleUrls: ['./modify-schedule.component.scss'],
})
export class ModifyScheduleComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////

  selectedEvents: EventData[] = [];

  allDataUserMap: Observable<Map<string, AllDataUser>>;

  eventTypes: Observable<EventType[]>;

  orgData: Observable<OrgData>;

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private allDataUserService: AllDataUserService,
    private orgDataService: OrgDataService,
  ) {}

  ngOnInit() {
    console.log('E2E debug: ModifyScheduleComponent.ngOnInit');
    this.allDataUserMap = this.allDataUserService.getDocs();
    this.orgData = this.orgDataService.getOrgData();
    this.eventTypes = this.orgData.pipe(map((z) => z.getEventTypes()));
  }
}
