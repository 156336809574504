import { Pipe, PipeTransform } from '@angular/core';

import { RowCell } from '../../../../../common/p-table/row-cell';

@Pipe({
  name: 'isRowCell',
  standalone: true,
})
export class IsRowCellPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  public transform<T>(value: unknown): value is RowCell<T> {
    return value instanceof RowCell;
  }
}
