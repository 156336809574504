import type {AnySchema} from 'joi';

import {DBMatchRuleDocPath} from '../../../../core/db-matcher/db-match-rule/db-match-rules/db-match-rule-doc-path/db-match-rule-doc-path';
import {GenericDisplayableSchema} from '../../../../generic/displayable/generic-displayable-schema';
import {SchemaField} from '../../../../generic/serialization/schema-field';
import {AnyAutomationAction} from '../../../automation-action/generic/any-automation-action/any-automation-action';
import {AutomationName} from '../../automation-name';

export class AutomationSchema extends GenericDisplayableSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly actions = 'actions';

  public static readonly triggerOn = 'triggerOn';

  public static readonly type = 'type';

  public static readonly Constants = class {
    public static readonly collection = 'automations';
  };

  public static readonly Defaults = class extends GenericDisplayableSchema.Defaults {
    public static readonly actions = undefined;
    public static readonly triggerOn = undefined;
    public static readonly type = undefined;
  };

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${AutomationSchema.Constants.collection}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[AutomationSchema.actions] = SchemaField.arrayOfType(SchemaField.obj(AnyAutomationAction, undefined), AutomationSchema.Defaults.actions).required();
    parameters[AutomationSchema.triggerOn] = SchemaField.obj(DBMatchRuleDocPath, AutomationSchema.Defaults.triggerOn).required();
    parameters[AutomationSchema.type] = SchemaField.string(AutomationSchema.Defaults.type)
      .required()
      .valid(...Object.values(AutomationName));

    return parameters;
  }
}
