import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-accordion-wizard-step',
  templateUrl: './accordion-wizard-step.component.html',
  styleUrls: ['./accordion-wizard-step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionWizardStepComponent implements OnChanges {
  private readonly cdr = inject(ChangeDetectorRef);

  protected active = false;

  @Input() public label = '';

  @Input() public hasError = false;

  @Output() public readonly changes = new EventEmitter<SimpleChanges>();

  public ngOnChanges(changes: SimpleChanges): void {
    this.changes.emit(changes);
  }

  public setActive(active: boolean): void {
    this.active = active;
    this.cdr.detectChanges();
  }
}
