import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { hoursToSeconds, minutesToSeconds } from 'date-fns';
import { cloneDeep, isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { startWith } from 'rxjs';

import { AllDataCommunicationWorkflowService } from '../../../../../services/communication/communication-workflow/all-data-communication-workflow/all-data-communication-workflow.service';
import { FormGroupControlValueAccessor } from '../../../../generic/abstract-classes/form-group-control-value-accessor';
import { CommunicationWorkflowEditorOutput } from '../editor-output/workflow/communication-workflow-editor-output';
import { defaultEditorOutput } from '../editor-output/workflow/db-to-editor-output/default-editor-output';
import { EditorFormType } from '../editor-output/workflow/form-to-editor-output/editor-form-type';
import { formToEditorOutput } from '../editor-output/workflow/form-to-editor-output/form-to-editor-output';
import { TaskReservationService } from '../task-reservation/task-reservation.service';

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CommunicationWorkflowEditorComponent),
  multi: true,
};

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CommunicationWorkflowEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-communication-workflow-editor',
  templateUrl: './communication-workflow-editor.component.html',
  styleUrls: ['./communication-workflow-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaskReservationService, MessageService, VALUE_ACCESSOR, VALIDATOR],
})
export class CommunicationWorkflowEditorComponent
  extends FormGroupControlValueAccessor<EditorFormType, CommunicationWorkflowEditorOutput>
  implements OnInit
{
  ////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////
  baseObj: CommunicationWorkflowEditorOutput | undefined;

  public readonly form = new FormGroup({
    general: new FormGroup({
      displayName: new FormControl(undefined, [Validators.required, Validators.maxLength(500)]),
      description: new FormControl(undefined, [Validators.maxLength(500)]),
    }),
    taskReservationTimeoutSeconds: new FormControl(defaultEditorOutput.taskReservationTimeoutSeconds, [
      Validators.required,
      Validators.min(minutesToSeconds(2)),
      Validators.max(hoursToSeconds(6)),
    ]),
    targets: new FormControl(),
  });

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private allDataCommunicationWorkflowService: AllDataCommunicationWorkflowService,
    private taskReservationService: TaskReservationService,
  ) {
    super(changeDetectorRef);
  }

  private trackTimeout(): void {
    this.form.controls.taskReservationTimeoutSeconds.valueChanges
      .pipe(startWith(this.form.controls.taskReservationTimeoutSeconds.value), untilDestroyed(this))
      .subscribe((value) => this.taskReservationService.timeoutSeconds$.next(value));
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.trackTimeout();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: CommunicationWorkflowEditorOutput) {
    if (isNil(value)) {
      return;
    }
    /**
     * Save provided input so we can re-use the id
     */
    this.baseObj = value ?? cloneDeep(defaultEditorOutput);

    /**
     * Update the form
     */
    const formValue = {
      general: {
        description: value.description,
        displayName: value.displayName,
      },
      taskReservationTimeoutSeconds: value.taskReservationTimeoutSeconds,
      targets: value.targets,
    };

    super.writeValue(formValue);
    this.taskReservationService.timeoutSeconds$.next(value.taskReservationTimeoutSeconds);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////
  parseValueChange(value: EditorFormType): CommunicationWorkflowEditorOutput {
    return formToEditorOutput(this.baseObj, value);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls / Steps
  ///////////////////////////////////////////////////////////////////////////////////////////
  get general(): UntypedFormGroup {
    return this.form.get('general') as UntypedFormGroup;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Complete
  ///////////////////////////////////////////////////////////////////////////////////////////
  completeWrapper = () => this.complete();

  private async complete() {
    const editorResult: CommunicationWorkflowEditorOutput = formToEditorOutput(this.baseObj, this.form.value as EditorFormType);
    try {
      await this.allDataCommunicationWorkflowService.update({
        workflowId: this.baseObj?.id,
        update: { editorResult },
        deleteWorkflowRequested: false,
      });
      this.successMessage();
    } catch (error) {
      this.failureMessage(error);
    }
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'communication-workflow-editor': this.form.value,
    };
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Success & Failure Messages
  /////////////////////////////////////////////////////////////////////////////////////////////

  successMessage() {
    const uploadSuccessTitle = translate(`communication-workflow-editor.uploadSuccessTitle`);
    this.messageService.add({ severity: 'success', summary: uploadSuccessTitle, life: 3000 });
  }

  failureMessage(error: any) {
    console.error(error);
    const title = translate('pwp-api.errorTimeoutTitle');
    const body = translate('pwp-api.errorTimeoutBody');

    this.messageService.add({ severity: 'error', summary: title, detail: body });
  }
}
