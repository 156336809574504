<ng-container *transloco="let t; read: 'automation-editor'">
  <ng-template #automationEditor>
    <form [attr.aria-label]="t('label')" [formGroup]="form">
      <app-accordion-wizard-steps>
        <app-accordion-wizard-step *ngIf="form.controls.display as display" [hasError]="display.invalid" [label]="t('display.label')">
          <label class="automation-editor__form-field">
            <span>{{ t('display.displayNameControl.label') }}</span>
            <input pInputText type="text" [formControl]="display.controls.name" />
          </label>
          <label class="automation-editor__form-field">
            <span>{{ t('display.descriptionControl.label') }}</span>
            <textarea pInputTextarea rows="4" [formControl]="display.controls.description"></textarea>
          </label>
        </app-accordion-wizard-step>
        <app-accordion-wizard-step *ngIf="form.controls.action as action" [hasError]="action.invalid" [label]="t('action.label')">
          <label class="automation-editor__form-field">
            <span>{{ t('action.subjectControl.label') }}</span>
            <input pInputText type="text" [formControl]="action.controls.subject" />
          </label>
          <label class="automation-editor__form-field">
            <span>{{ t('action.emailControl.label') }}</span>
            <p-editor
              [formControl]="action.controls.message"
              [style]="{ height: '20rem' }"
              [styleClass]="'automation-editor__form-html-editor'"
            ></p-editor>
          </label>
        </app-accordion-wizard-step>
        <app-accordion-wizard-step *ngIf="form.controls.trigger as trigger" [hasError]="trigger.invalid" [label]="t('trigger.label')">
          <label class="automation-editor__form-field">
            <span>{{ t('trigger.communicationsControl.label') }}</span>
            <app-automation-trigger-autocomplete
              class="p-autocomplete--full-width"
              [formControl]="trigger.controls.paths"
            ></app-automation-trigger-autocomplete>
          </label>
        </app-accordion-wizard-step>
      </app-accordion-wizard-steps>
    </form>
  </ng-template>

  <app-settings
    [auditEntry]="automation | auditEntry"
    [doUpload]="saveAutomation"
    [editorTemplate]="automationEditor"
    [isValid]="isValid"
    [refreshData]="refreshData"
    [resetForm]="resetForm"
  >
  </app-settings>
</ng-container>
