import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { UploadMode } from '../interfaces';

import { BatchDocToWrite } from './batch-doc-to-write';
import { getSetOptionsFromMode } from './set-options-for-mode';
//////////////////////////////////////////////////////////////////////////////////////////
// Constants
//////////////////////////////////////////////////////////////////////////////////////////

// At most 500 docs per batch, but serverTimestamp() counts as extra write
const MAX_DOCS_IN_BATCH = 200;

//////////////////////////////////////////////////////////////////////////////////////////
// Main Export
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Write the specified arbitrary list of documents to firestore in batches, and
 * return the associated list of promises.
 *
 * @param db Database reference
 * @param docs Array of documents to write
 */
export const writeDocsInBatches = (db: AngularFirestore, docs: BatchDocToWrite[], mode: UploadMode): Promise<void> => {
  let batch = firebase.firestore().batch();

  let numDocsInBatch = 0;
  const setOptions = getSetOptionsFromMode(mode);
  const promises: Promise<void>[] = [];
  for (const doc of docs) {
    batch.set(doc.targetRef, doc.data, setOptions);
    numDocsInBatch += 1;
    if (numDocsInBatch < MAX_DOCS_IN_BATCH) {
      continue;
    } else {
      promises.push(batch.commit());
      numDocsInBatch = 0;
      batch = firebase.firestore().batch();
    }
  }
  if (numDocsInBatch > 0) {
    promises.push(batch.commit());
    numDocsInBatch = 0;
  }
  return Promise.all(promises).then((_) => null);
};
