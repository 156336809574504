import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { FirebaseAuthAction } from '../../enums/firebase-auth-action';

@Injectable({
  providedIn: 'root',
})
export class FirebaseActionGuard implements CanActivate {
  private readonly router = inject(Router);

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const { queryParams } = route;

    if (queryParams?.mode === FirebaseAuthAction.resetPassword) {
      return this.router.createUrlTree(['/auth/reset-password'], { queryParams });
    }

    if (route.queryParams?.mode === FirebaseAuthAction.verifyAndChangeEmail) {
      return this.router.createUrlTree(['/auth/verify-email'], { queryParams });
    }

    return false;
  }
}
