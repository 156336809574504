import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';

import { DisplayTimestampPipe } from '../../../../common/date/display-time/display-time.pipe';
import { IsMomentPipe } from '../../../../common/date/is-moment/is-moment.pipe';
import { PTableCol } from '../../../../common/p-table/p-table-col';
import { RowCell } from '../../../../common/p-table/row-cell';

import { IsRowCellPipe } from './is-row-cell/is-row-cell.pipe';

@Component({
  selector: 'app-row-cell',
  standalone: true,
  imports: [CommonModule, DisplayTimestampPipe, IsMomentPipe, IsRowCellPipe, MatIconModule, TranslocoModule],
  templateUrl: './row-cell.component.html',
  styleUrls: ['./row-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowCellComponent {
  @Input() public rowData: Record<string, RowCell<unknown> | string | number | moment.Moment>;

  @Input() public col: PTableCol;
}
