import { CallListSchema, DBDocSchema } from '@pwp-common';

import { defaultOfferConfigRingTimeEditorOutput } from '../../../call-list-offer-config-ring-time/editor/editor-output/default-offer-config-ring-time-editor-output';

import { CallListEditorOutput } from './call-list-editor-output';

export const defaultCallListEditorOutput: CallListEditorOutput = {
  id: DBDocSchema.GenericDefaults.id,
  displayName: CallListSchema.Defaults.displayName,
  description: CallListSchema.Defaults.description,
  calleeEntities: [],
  offerConfig: {
    ringTime: defaultOfferConfigRingTimeEditorOutput,
  },
};
