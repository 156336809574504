import {CommunicationSessionType} from '../../../../call/communication-session-type';
import {FormSubmission} from '../../form-submission';

import {GetFormSubmissionReportInfoOutput} from './interfaces';

export const getFormSubmissionReportInfo = (formSubmissions: FormSubmission[]): GetFormSubmissionReportInfoOutput =>
  formSubmissions.reduce(
    (acc, submission) => {
      const sessionId = submission.getSessionId();
      const communicationSessionType = sessionId.getCommunicationSessionType();

      acc.formVersionIds.add(submission.getFormVersionId());

      if (communicationSessionType === CommunicationSessionType.inboundCall) {
        acc.callLogIds.add(sessionId.getInboundCallSessionId() as string);
      } else {
        acc.conversationLogIds.add(sessionId.getConversationLogId() as string);
      }

      return acc;
    },
    {
      formVersionIds: new Set<string>(),
      conversationLogIds: new Set<string>(),
      callLogIds: new Set<string>(),
    }
  );
