import { ValidatorFn } from '@angular/forms';

export const mustMatch =
  (controlName: string, matchingControlName: string): ValidatorFn =>
  (control) => {
    const sourceControl = control.get(controlName);
    const confirmationControl = control.get(matchingControlName);

    return sourceControl.value === confirmationControl.value ? null : { mustMatch: true };
  };
