import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, ValidationErrors, Validator } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { first } from 'rxjs';

@Directive()
export abstract class FormGroupControlValueAccessor<FormValue, EmittedOutput> implements OnInit, ControlValueAccessor, Validator {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected onTouched?: () => void;

  public form: FormGroup | FormControl;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    this.defineForm?.();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected abstract parseValueChange(value: FormValue): EmittedOutput;

  protected makeValidationErrors(): ValidationErrors {
    return null;
  }

  protected defineForm?(): void;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  public ngOnInit(): void {
    this.form.statusChanges
      .pipe(
        first(() => this.form.touched),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.onTouched?.();
      });
  }

  public writeValue(value: unknown): void {
    if (isNil(value)) {
      return;
    }
    this.form.patchValue(value, { emitEvent: false });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Register On Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  public registerOnChange(fn: (any) => unknown): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value: FormValue) => {
      const parsedResult = this.parseValueChange(value);
      fn(parsedResult);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Register On Touched
  /////////////////////////////////////////////////////////////////////////////////////////////

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Set Disabled State
  /////////////////////////////////////////////////////////////////////////////////////////////

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation
  /////////////////////////////////////////////////////////////////////////////////////////////

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.touched) {
      this.form.markAllAsTouched();
    }

    if (this.form.valid) {
      return null;
    }

    return this.makeValidationErrors();
  }
}
