import { Routes } from '@angular/router';

import { LoginComponent as LegacyLoginComponent } from '../../pages/login/login.component';

import { FirebaseAuthAction } from './enums/firebase-auth-action';
import { FirebaseActionGuard } from './guards/firebase-action/firebase-action.guard';
import { NoAuthGuard } from './guards/no-auth/no-auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordGuard } from './router/reset-password/reset-password.guard';

export const authRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
  { path: 'login', pathMatch: 'full', redirectTo: '/auth/login' },
  { path: 'auth/action', component: LoginComponent, canActivate: [FirebaseActionGuard] },
  { path: 'auth/verify-email', component: LegacyLoginComponent, data: { mode: FirebaseAuthAction.verifyAndChangeEmail } },
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('../../layouts/card-layout/card-layout.component').then((m) => m.CardLayoutComponent),
    children: [
      {
        path: 'login',
        loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
      },
      {
        path: 'forgot-password',
        loadComponent: () => import('./pages/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
      },
      {
        path: 'reset-password',
        canActivate: [ResetPasswordGuard],
        loadComponent: () => import('./pages/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
      },
      {
        path: 'reset-password-error',
        loadComponent: () =>
          import('./pages/reset-password-error/reset-password-error.component').then((m) => m.ResetPasswordErrorComponent),
      },
    ],
  },
];
