import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { merge, startWith, switchMap } from 'rxjs';

import { AccordionWizardStepComponent } from '../accordion-wizard-step/accordion-wizard-step.component';

@UntilDestroy()
@Component({
  selector: 'app-accordion-wizard-steps',
  templateUrl: './accordion-wizard-steps.component.html',
  styleUrls: ['./accordion-wizard-steps.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionWizardStepsComponent implements AfterContentInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Input() public complete: () => Promise<void> = () => Promise.resolve();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  public activeIndex = 0;

  public continueLoadingIndicator = false;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @ContentChildren(AccordionWizardStepComponent, { read: AccordionWizardStepComponent })
  public steps: QueryList<AccordionWizardStepComponent>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  private monitorStepChanges(): void {
    this.steps.changes
      .pipe(
        startWith(null),
        switchMap(() => merge(...this.steps.map((step) => step.changes))),
        untilDestroyed(this),
      )
      .subscribe(() => {
        console.log('Step changes');
        this.changeDetectorRef.markForCheck();
      });
  }

  /**
   * This code is executed once. It's like ngOnInit, but run after ContentChildren is initialized.
   */
  public ngAfterContentInit(): void {
    this.updateVisibleStepComponent();
    this.monitorStepChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Update Visible Step Component
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  public updateVisibleStepComponent(): void {
    if (isNil(this.steps)) {
      return;
    }

    this.steps.forEach((step, index) => {
      step.setActive(index === this.activeIndex);
    });

    this.changeDetectorRef.markForCheck();
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async triggerStepAfterIndex(index: number): Promise<void> {
    if (isNil(index) || isNil(this.steps)) {
      return;
    }

    if (index < this.steps.length - 1) {
      this.activeIndex += 1;
    } else {
      // Trigger the complete method.
      try {
        this.continueLoadingIndicator = true;
        await this.complete();
        this.activeIndex = -1;
      } catch (error) {
        console.error(error);
      } finally {
        this.continueLoadingIndicator = false;
      }
    }

    this.updateVisibleStepComponent();
    this.changeDetectorRef.detectChanges();
  }
}
