import {cloneDeep} from 'lodash';

import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';

import {AutomationActionConstructor} from './automation-action-constructor';

export class AutomationAction extends SerializableObject {
  protected type!: string;

  constructor(parameters: AutomationActionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract AuomationAction');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): string {
    return cloneDeep(this.type);
  }
}
