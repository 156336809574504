import type {AnySchema} from 'joi';

import {AnyAutomation} from '../../../../../objects/automation/automation/generic/any-automation/any-automation';
import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {AutomationRequestSchema} from '../../generic/automation-request/automation-request-schema';

export class AutomationRequestCreateAutomationSchema extends AutomationRequestSchema {
  public static readonly automation = 'automation';

  public static readonly automationId = 'automationId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends AutomationRequestSchema.Defaults {
    public static readonly automation = undefined;
    public static readonly automationId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[AutomationRequestCreateAutomationSchema.automation] = SchemaField.obj(AnyAutomation, AutomationRequestCreateAutomationSchema.Defaults.automation);
    parameters[AutomationRequestCreateAutomationSchema.automationId] = SchemaField.autoGeneratedDocId(AutomationRequestCreateAutomationSchema.Defaults.automationId);

    return parameters;
  }
}
