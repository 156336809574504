////////////////////////////////////////////////////////////////////////////////////////////////
// Request & Response / Generic
////////////////////////////////////////////////////////////////////////////////////////////////

export {AnyAutomationRequest} from './request/generic/any-automation-request/any-automation-request';
export {AnyAutomationRequestSchema} from './request/generic/any-automation-request/any-automation-request-schema';
export {AutomationRequest} from './request/generic/automation-request/automation-request';

export {AnyAutomationResponse} from './response/generic/any-automation-response/any-automation-response';
export {AnyAutomationResponseSchema} from './response/generic/any-automation-response/any-automation-response-schema';
export {AutomationResponse} from './response/generic/automation-response/automation-response';

////////////////////////////////////////////////////////////////////////////////////////////////
// Request / Create automation
////////////////////////////////////////////////////////////////////////////////////////////////

export {AutomationRequestCreateAutomation} from './request/requests/automation-request-create-automation/automation-request-create-automation';
export {AutomationRequestCreateAutomationConstructor} from './request/requests/automation-request-create-automation/automation-request-create-automation-constructor';
export {AutomationRequestCreateAutomationSchema} from './request/requests/automation-request-create-automation/automation-request-create-automation-schema';

export {AutomationResponseCreateAutomation} from './response/responses/automation-response-create-automation/automation-response-create-automation';
export {AutomationResponseCreateAutomationConstructor} from './response/responses/automation-response-create-automation/automation-response-create-automation-constructor';
export {AutomationResponseCreateAutomationError} from './response/responses/automation-response-create-automation/automation-response-create-automation-error';
export {AutomationResponseCreateAutomationSchema} from './response/responses/automation-response-create-automation/automation-response-create-automation-schema';

////////////////////////////////////////////////////////////////////////////////////////////////
// Warning On Import
////////////////////////////////////////////////////////////////////////////////////////////////

console.warn('--------- pwp-common:callable-functions/automation/index.ts: Imported ---------');
