import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  AnyAutomationResponse,
  AutomationOnCommunicationComplete,
  AutomationRequest,
  AutomationRequestCreateAutomation,
  AutomationResponse,
  AutomationResponseCreateAutomation,
} from '@pwp-common';

import { CallableFunctionService } from '../../../services/generic/callable-function-service/callable-function-service';

@Injectable({
  providedIn: 'root',
})
export class AutomationEndpointService extends CallableFunctionService<AutomationRequest, AutomationResponse<unknown>> {
  constructor(fns: AngularFireFunctions) {
    super(AnyAutomationResponse, fns);
  }

  // eslint-disable-next-line class-methods-use-this
  public getFunctionName(): string {
    return 'automation';
  }

  public createAutomation(automation: AutomationOnCommunicationComplete): Promise<AutomationResponseCreateAutomation> {
    return this.makeRequest(
      new AutomationRequestCreateAutomation({
        automation,
        automationId: automation.getId(),
      }),
    );
  }
}
