<p-toast></p-toast>

<ng-container *transloco="let t; read: 'add-user-dialog'">
  <form *ngrxLet="profileForm.controls as controls" class="add-user-dialog__form" [formGroup]="profileForm">
    <label *ngrxLet="controls.firstName as firstName" class="add-user-dialog__form-field">
      <span class="add-user-dialog__form-field-label">{{ t('firstName.label') }}</span>

      <input pInputText [formControl]="firstName" />

      <app-form-field-help [control]="firstName">
        <ng-container form-field-help-errors>
          <div *ngIf="firstName.errors?.required">
            {{ t('firstName.errorRequired') }}
          </div>
        </ng-container>
      </app-form-field-help>
    </label>

    <label *ngrxLet="controls.lastInitial as lastInitial" class="add-user-dialog__form-field">
      <span class="add-user-dialog__form-field-label">{{ t('lastInitial.label') }}</span>

      <input pInputText [formControl]="lastInitial" />

      <app-form-field-help [control]="lastInitial">
        <ng-container form-field-help-errors>
          <div *ngIf="lastInitial.errors?.required">
            {{ t('lastInitial.errorRequired') }}
          </div>
        </ng-container>
      </app-form-field-help>
    </label>

    <label *ngrxLet="controls.email as email" class="add-user-dialog__form-field">
      <span class="add-user-dialog__form-field-label">{{ t('notificationEmail.label') }}</span>

      <input pInputText [formControl]="email" />

      <app-form-field-help [control]="email">
        <ng-container form-field-help-errors>
          <div *ngIf="email.errors?.required">
            {{ t('notificationEmail.errorRequired') }}
          </div>
          <div *ngIf="email.errors?.email">
            {{ t('notificationEmail.errorInvalid') }}
          </div>
          <div *ngIf="email.errors?.notInList">
            {{ t('notificationEmail.errorNotInList') }}
          </div>
        </ng-container>
      </app-form-field-help>
    </label>

    <label *ngrxLet="controls.e164Phone as e164Phone" class="add-user-dialog__form-field">
      <span class="add-user-dialog__form-field-label">{{ t('usTelephone.label') }}</span>

      <p-inputMask mask="(999) 999-9999" styleClass="inputfield w-full" [formControl]="controls.e164Phone" [unmask]="true"> </p-inputMask>

      <app-form-field-help [control]="e164Phone">
        <ng-container form-field-help-hint>
          {{ t('usTelephone.hint', { value: e164Phone.value }) }}
        </ng-container>

        <ng-container form-field-help-errors>
          <div *ngIf="e164Phone.errors?.required">
            {{ t('usTelephone.errorRequired') }}
          </div>
          <div *ngIf="e164Phone.errors?.usLocalPhone">
            {{ t('usTelephone.errorInvalid') }}
          </div>
        </ng-container>
      </app-form-field-help>
    </label>

    <label *ngrxLet="controls.roles as roles" class="add-user-dialog__form-field">
      <span class="add-user-dialog__form-field-label">{{ t('roles.label') }}</span>

      <p-multiSelect
        class="add-user-dialog__dropdown"
        [defaultLabel]="t('roles.noRole.label')"
        [formControl]="roles"
        [maxSelectedLabels]="2"
        [options]="rolesOptions$ | async"
        [selectedItemsLabel]="'{0} ' + t('roles.selectedRolesSuffix') | lowercase"
        [showHeader]="false"
      >
        <ng-template pTemplate="item" let-item>
          <div class="add-user-dialog__dropdown-item">
            <span>{{ item.label }}</span>
            <span class="add-user-dialog__dropdown-item-description">{{ item.description }}</span>
          </div>
        </ng-template>
      </p-multiSelect>
    </label>

    <label *ngrxLet="controls.orgNote as orgNote" class="add-user-dialog__form-field">
      <span class="add-user-dialog__form-field-label">{{ t('orgNote.label') }}</span>

      <textarea pInputTextarea [formControl]="orgNote"></textarea>

      <app-form-field-help [control]="orgNote">
        <ng-container form-field-help-hint>
          {{ t('orgNote.hint') }}
        </ng-container>
      </app-form-field-help>
    </label>

    <ul *ngIf="orgData$ | async as orgData" class="add-user-dialog__consent-terms">
      <li [innerHTML]="t('consentTerms.authorizedRepresentative', orgData)"></li>
      <li [innerHTML]="t('consentTerms.newUserConsentedWithMessages', orgData)"></li>
      <li [innerHTML]="t('consentTerms.messageLimit', orgData)"></li>
      <li [innerHTML]="t('consentTerms.cancelSmsService', orgData)"></li>
      <li [innerHTML]="t('consentTerms.assistance')"></li>
      <li [innerHTML]="t('consentTerms.carriersAndHelplineResponsibility')"></li>
      <li [innerHTML]="t('consentTerms.ratesApplied')"></li>
      <li [innerHTML]="t('consentTerms.privacyPolicy')"></li>
    </ul>

    <p-checkbox
      class="p-checkbox--centered"
      [ariaLabel]="t('agreeWithConsentTerms')"
      [binary]="true"
      [formControl]="profileForm.controls.agreeWithConsentTerms"
      [label]="t('agreeWithConsentTerms')"
    ></p-checkbox>
  </form>

  <div class="add-user-dialog__actions">
    <button pButton class="p-button-text" (click)="cancel()">{{ t('cancel') }}</button>
    <button
      pButton
      class="add-user-dialog__button"
      [disabled]="!profileForm.valid || uploadInProgress"
      [loading]="uploadInProgress"
      (click)="addUser()"
    >
      <mat-icon class="add-user-dialog__button-icon">send</mat-icon>
      {{ t('addUser') }}
    </button>
  </div>
</ng-container>
