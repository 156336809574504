<ng-container *transloco="let t; read: 'automation-trigger-autocomplete'">
  <p-autoComplete
    appendTo="body"
    field="displayName"
    [dropdown]="true"
    [dropdownAriaLabel]="t('dropdownLabel')"
    [emptyMessage]="t('noOptionsFound')"
    [formControl]="form"
    [group]="true"
    [inputId]="inputId || defaultId"
    [multiple]="true"
    [ngClass]="{ 'ng-invalid': form?.invalid }"
    [placeholder]="t('placeholder')"
    [showEmptyMessage]="true"
    [suggestions]="suggestions$ | async"
    (completeMethod)="search($event)"
  >
    <ng-template pTemplate="item" let-item>
      <div class="automation-trigger-autocomplete__item">
        <span>{{ item.label }}</span>
      </div>
    </ng-template>

    <ng-template pTemplate="selectedItem" let-item>
      <div class="automation-trigger-autocomplete__selected-item">
        <mat-icon class="automation-trigger-autocomplete__selected-item-icon" fontSet="material-icons-outlined">{{ item.icon }}</mat-icon>
        <span>{{ item.label }}</span>
      </div>
    </ng-template>
  </p-autoComplete>

  <div *ngIf="form.errors as errors" class="p-error">
    <span *ngIf="errors.notAValidOption">{{ t('error.objIdNotInList') }}</span>
  </div>
</ng-container>
