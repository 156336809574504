import { AutomationActionSendEmail, AutomationOnCommunicationComplete } from '@pwp-common';

import { AutomationEditorOutput } from '../automation-editor-output';

export const createAutomationEditorOutput = (automation: AutomationOnCommunicationComplete): AutomationEditorOutput => {
  const [action] = automation.getActions() as [AutomationActionSendEmail];

  return {
    display: {
      name: automation.getDisplayName(),
      description: automation.getDescription(),
    },
    action: {
      subject: action.getSubject(),
      message: action.getBody(),
    },
    trigger: {
      paths: automation.getTriggerOn().getOrgScopedPaths(),
    },
  };
};
