import {cloneDeep} from 'lodash';

import {DBDocSchema} from '../../../generic/db-doc/db-doc-schema';
import {GenericDisplayable} from '../../../generic/displayable/generic-displayable';
import {VoiceResponseCommand} from '../../../voice-response-command/generic/voice-response-command';
import {CalleeEntity} from '../../callee-entity/callee-entity';
import {CallListExtensionConfig} from '../extension-config/call-list-extension-config/call-list-extension-config';

import {CallListConstructor} from './call-list-constructor';
import {CallListSchema} from './call-list-schema';
import {defaultCallListOfferConfig} from './constants';
import {CallListOfferConfig} from '../offer-config/call-list-offer-config/call-list-offer-config';

export class CallList extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected calleeEntities!: CalleeEntity[];
  protected confirmJoinIVRId!: string;
  protected capacity!: number;
  protected onNoUserLeftToDialModIncCallCommands!: VoiceResponseCommand[];
  protected callerHangupOnStarCommands?: VoiceResponseCommand[];
  protected calleeHangupOnStarCommands?: VoiceResponseCommand[];
  protected dialTimeoutSeconds!: number;
  protected pIIRetentionDuration!: string;
  protected offerConfig!: CallListOfferConfig;
  protected extensionConfig!: CallListExtensionConfig;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallListConstructor) {
    super(parameters);
    this.offerConfig = parameters.offerConfig ?? defaultCallListOfferConfig;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CallList {
    return new CallList(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CallList.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new CallListSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCalleeEntities(): CalleeEntity[] {
    return cloneDeep(this.calleeEntities);
  }

  public getCapacity(): number {
    return cloneDeep(this.capacity);
  }

  public getConfirmJoinIVRId(): string {
    return cloneDeep(this.confirmJoinIVRId);
  }

  public getOnNoUserLeftToDialModIncCallCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.onNoUserLeftToDialModIncCallCommands);
  }

  public getCallerHangupOnStarCommands(): VoiceResponseCommand[] | undefined {
    return cloneDeep(this.callerHangupOnStarCommands);
  }

  public getCalleeHangupOnStarCommands(): VoiceResponseCommand[] | undefined {
    return cloneDeep(this.calleeHangupOnStarCommands);
  }

  public getDialTimeoutSeconds(): number {
    return cloneDeep(this.dialTimeoutSeconds);
  }

  public getPIIRetentionDuration(): string {
    return cloneDeep(this.pIIRetentionDuration);
  }

  public getOfferConfig() {
    return cloneDeep(this.offerConfig);
  }

  public getExtensionConfig() {
    return cloneDeep(this.extensionConfig);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setCalleeEntities(calleeEntities: CalleeEntity[]): CallList {
    this.calleeEntities = cloneDeep(calleeEntities);
    return this;
  }

  public setCapacity(capacity: number): CallList {
    this.capacity = capacity;
    return this;
  }

  public setConfirmJoinIVRId(confirmJoinIVRId: string): CallList {
    this.confirmJoinIVRId = confirmJoinIVRId;
    return this;
  }

  public setOnNoUserLeftToDialModIncCallCommands(commands: VoiceResponseCommand[]): CallList {
    this.onNoUserLeftToDialModIncCallCommands = commands;
    return this;
  }

  public setCallerHangupOnStarCommands(commands: VoiceResponseCommand[]): CallList {
    this.callerHangupOnStarCommands = commands;
    return this;
  }

  public setCalleeHangupOnStarCommands(commands: VoiceResponseCommand[]): CallList {
    this.calleeHangupOnStarCommands = commands;
    return this;
  }

  public setDialTimeoutSeconds(value: number): CallList {
    this.dialTimeoutSeconds = value;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
