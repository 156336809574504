import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { isNil } from 'lodash';
import { firstValueFrom, forkJoin, from, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import {
  DBAction,
  DBCreateDoc,
  DBDeleteDoc,
  DBDocObject,
  DBDocSchema,
  DBTransaction,
  DBUploadExistingDoc,
  isEditingStaleObject,
  isNilOrDefaultDocId,
  SerializableDocBuilder,
} from '@pwp-common';

import { AuthService } from '../user/auth/auth.service';

import { BatchDocToWrite } from './batch/batch-doc-to-write';
import { commitBatchObservable } from './batch/commit-batch-observable';
import { getSetOptionsFromMode } from './batch/set-options-for-mode';
import { writeDocsInBatches } from './batch/write-docs-in-batches';
import { parseGetDocsArrayOptions } from './helper/parse-get-docs-array-options/parse-get-docs-array-options';
import { DBOrderBy, DBQuery, GetDocsArrayOptions, UploadDictionary, UploadMode } from './interfaces';
import { observableTakeOne } from './take-one';

export abstract class DbDocumentService<T extends DBDocObject> {
  protected orgId: string | undefined;

  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    protected db: AngularFirestore,
    protected authService: AuthService,
    private objGenerator: SerializableDocBuilder<T>,
  ) {}

  ///////////////////////////////////////////////////////////////////////
  // Get Doc(s)
  ///////////////////////////////////////////////////////////////////////

  /**
   * Get data for the object with given id.
   *
   * @param docId ID of the document to get.
   * @param takeOne If true, have the observable complete as soon as an object is returned.
   */
  public getDoc(docId: string, defaultValue?: T, takeOne = true): Observable<T | undefined> {
    const observable = this.docRef(docId).pipe(
      switchMap((docRef, _) => docRef.get({ source: 'server' })),
      map((rawDoc) => {
        const parsedObject: T = this.getObjectFromFirebase(rawDoc, defaultValue);
        return parsedObject;
      }),
      catchError((err, caught) => {
        console.error(`DbDocumentService.getDoc: ${this.getLoggingInfo()}, docId=${docId}, takeOne=${takeOne}: Error`);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
      finalize(() => console.log(`DbDocumentService.getDoc:${this.getLoggingInfo()}, docId=${docId}, takeOne=${takeOne}: Complete`)),
    );

    return observableTakeOne(observable, takeOne);
  }

  /**
   * Get all documents with the specified IDs.
   *
   * @param docIds array of document Ids.
   */
  public getDocsWithIds(docIds: string[]): Observable<Map<string, T>> {
    if (docIds.length === 0) {
      return of(new Map<string, T>());
    }

    const observables: Observable<T>[] = [];

    for (const docId of new Set(docIds)) {
      observables.push(this.getDoc(docId));
    }

    const observable = forkJoin(observables).pipe(
      map((objs, _) => {
        const result = new Map<string, T>();
        for (const obj of objs) {
          if (isNil(obj)) {
            continue;
          }
          result.set(obj.getId(), obj);
        }
        return result;
      }),
      catchError((err, caught) => {
        console.error(`DbDocumentService.getDocsWithIds: ${this.getLoggingInfo()}, docIds=${docIds.join(',')}: Error`);
        console.error('docIds');
        console.error(docIds);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
      finalize(() => console.log(`DbDocumentService.getDocsWithIds: ${this.getLoggingInfo()}, docIds=${docIds.join(',')}: Complete`)),
    );

    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Collection
  ///////////////////////////////////////////////////////////////////////

  /**
   * Get all data from the collection
   *
   * @param takeOne If true, have the observable complete as soon as an object is returned.
   */
  public getDocs(query: DBQuery[] = [], orderBy?: DBOrderBy, limit?: number, takeOne = true): Observable<Map<string, T>> {
    return this.getDocsArray(query, orderBy, limit, takeOne).pipe(
      map((docsArr) => {
        const resultMap = new Map<string, T>();
        for (const doc of docsArr) {
          resultMap.set(doc.getId(), doc);
        }
        return resultMap;
      }),
    );
  }

  ///////////////////////////////////////////////////////////////////////
  // Docs Array
  ///////////////////////////////////////////////////////////////////////

  public getDocsArray(options?: GetDocsArrayOptions): Observable<T[]>;
  /**
   * @deprecated Use the options object instead
   */
  public getDocsArray(query?: DBQuery[], orderBy?: DBOrderBy, limit?: number, takeOne?: boolean): Observable<T[]>;
  public getDocsArray(
    queryOrOptions?: DBQuery[] | GetDocsArrayOptions,
    orderByParam?: DBOrderBy,
    limitParam?: number,
    takeOneParam?: boolean,
  ): Observable<T[]> {
    const { query, orderBy, limit, takeOne } = parseGetDocsArrayOptions(queryOrOptions, orderByParam, limitParam, takeOneParam);

    const observable = this.collectionRefWithQuery(query, orderBy, limit).pipe(
      switchMap((collectionRef, _) => {
        if (takeOne) {
          return collectionRef.get({ source: 'server' }).pipe(
            map((querySnapshot, __) => {
              const resultArr: T[] = [];
              for (const doc of querySnapshot.docs) {
                const parsedObject: T = this.getObjectFromFirebase(doc, undefined);
                if (parsedObject === undefined) {
                  continue;
                }
                resultArr.push(parsedObject);
              }
              return resultArr;
            }),
          );
        }
        // This is for streaming data from firebase
        return collectionRef.snapshotChanges().pipe(
          map((actions, __) => {
            const resultArr: T[] = [];

            for (const action of actions) {
              const parsedObject = this.getObjectFromFirebase(action?.payload?.doc, undefined);
              if (parsedObject === undefined) {
                continue;
              }
              resultArr.push(parsedObject);
            }
            return resultArr;
          }),
        );
      }),
      catchError((err, caught) => {
        console.error(`DbDocumentService.getDocsArray:${this.getLoggingInfo()}, takeOne=${takeOne}: Error`);
        console.error('query');
        console.error(query);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
      finalize(() => console.log(`DbDocumentService.getDocsArray:${this.getLoggingInfo()}, takeOne=${takeOne}: Complete`)),
    );

    return observableTakeOne(observable, takeOne);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Unique Doc
  ///////////////////////////////////////////////////////////////////////

  public getUniqueDoc(parameters: {
    query: DBQuery[];
    logErrIfNoDocExists?: boolean;
    orderBy?: DBOrderBy;
    takeOne?: boolean;
  }): Observable<T | undefined> {
    return this.getDocsArray(parameters.query, parameters.orderBy, 1, parameters.takeOne).pipe(
      map((items: T[]) => {
        if (items.length <= 0) {
          if (parameters.logErrIfNoDocExists) {
            console.error('DbDocumentService.getUniqueDoc: No documents returned, but one was expected:' + `${this.getLoggingInfo()}`, {
              parameters,
            });
          }
          return undefined;
        }
        return items[0];
      }),
    );
  }

  ///////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////

  public createId(): string {
    return this.db.createId();
  }

  /**
   * Append the given item to the specified batch
   *
   * @param obj Object to upload
   * @param mode if create, then (merge = false) overwrite any existing fields. If update, then merge with existing fields (merge = true).
   * @param generateId If true, then do not call T.getId() to get the documentId to upload at. Instead, generate a new one.
   * @param batch The batch to append write to
   */
  public uploadInBatch(params: {
    obj: T;
    mode: UploadMode;
    generateId?: boolean;
    batch: firebase.firestore.WriteBatch;
    overrides?: UploadDictionary;
  }): Observable<firebase.firestore.WriteBatch> {
    const generateId = params.generateId ?? false;

    const id = this.getUploadId(params.obj, generateId);
    let uploadDictionary: UploadDictionary;

    const observable = forkJoin([this.getSerializedObjWithGenericFields(params.obj, params.mode), this.docRef(id)]).pipe(
      map((value, _) => {
        uploadDictionary = { ...value[0], ...(params.overrides ?? {}) };
        const docRef = value[1];
        return params.batch.set(docRef.ref, uploadDictionary, getSetOptionsFromMode(params.mode));
      }),
      catchError((err, caught) => {
        console.error(`DbDocumentService.uploadInBatch:${this.getLoggingInfo()}:
                mode=${params.mode}, id=${id}, generateId=${generateId}: Error`);
        console.error('DbDocumentService.uploadInBatch: err');
        console.error(err);
        console.error('DbDocumentService.uploadInBatch: caught');
        console.error(caught);
        console.error((params.obj as any).serialize());
        throw err;
      }),
      finalize(() =>
        console.log(`DbDocumentService.uploadInBatch:${this.getLoggingInfo()}:
            mode=${params.mode}, id=${id}, generateId=${generateId}: Complete`),
      ),
    );
    return observable;
  }

  /**
   * Upload the given object to this service's collection. If mode isn't specified then we create the document if
   * the id field is undefined, the empty string, or the default value.
   *
   * @param obj Object to upload
   * @param mode if create, then (merge = false) overwrite any existing fields. If update, then merge with existing fields (merge = true).
   * @param generateId If true, then do not call T.getId() to get the documentId to upload at. Instead, generate a new one.
   */
  public upload(params: { obj: T; mode?: UploadMode; generateId?: boolean; overrides?: UploadDictionary }): Observable<void> {
    let calculatedMode: UploadMode;
    let calculatedGenerateId: boolean;

    if (params.mode !== undefined) {
      calculatedMode = params.mode;
      calculatedGenerateId = params.generateId ?? false;
    } else if ([DBDocSchema.GenericDefaults.id, undefined, ''].includes(params.obj.getId())) {
      calculatedMode = 'create';
      calculatedGenerateId = true;
    } else {
      calculatedMode = 'update';
      calculatedGenerateId = false;
    }

    console.log((params.obj as any).serialize());
    const batch = this.uploadInBatch({
      obj: params.obj,
      mode: calculatedMode,
      generateId: calculatedGenerateId,
      batch: firebase.firestore().batch(),
      overrides: params.overrides,
    });
    return commitBatchObservable(batch);
  }

  public uploadArray(objs: T[], mode: UploadMode, generateIds = false): Observable<void> {
    // These observables are simply used to construct the batch.
    const serializedObjObservables: Observable<BatchDocToWrite>[] = [];

    for (const obj of objs) {
      const id = this.getUploadId(obj, generateIds);
      const serializedObjObservable = forkJoin([this.docRef(id), this.getSerializedObjWithGenericFields(obj, mode)]).pipe(
        map(([docRef, serializedObj], _) => ({ targetRef: docRef.ref, data: serializedObj })),
      );
      serializedObjObservables.push(serializedObjObservable);
    }

    // Final observable containing write result
    const observable = forkJoin(serializedObjObservables).pipe(
      switchMap((docsToWrite, _) => writeDocsInBatches(this.db, docsToWrite, mode)),
      catchError((err, caught) => {
        console.error(`DbDocumentService.uploadArray:${this.getLoggingInfo()}, mode=${mode}, generateIds=${generateIds}: Error`);
        console.error('objs');
        console.error(objs);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
      finalize(() =>
        console.log(`DbDocumentService.uploadArray:${this.getLoggingInfo()}, mode=${mode}, generateIds=${generateIds}: Complete`),
      ),
    );

    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // Upload If Not Modified After
  ///////////////////////////////////////////////////////////////////////

  /**
   * Update the given object, but only if it was not modified
   * after the given requested update.
   */
  public async uploadIfNotEditingStaleObject(requestedUpdate: T): Promise<boolean> {
    console.log('DbDocumentService.uploadOnlyIfUnchanged: Starting');
    if (isNilOrDefaultDocId(requestedUpdate?.getId())) {
      throw new Error('DbDocumentService.uploadIfNotEditingStaleObject: The required docId is missing');
    }
    const currentDbObj = await lastValueFrom(this.getDoc(requestedUpdate.getId()));
    const userEditingTheObject = await firstValueFrom(this.authService.getUserId());
    if (isEditingStaleObject({ original: currentDbObj, updated: requestedUpdate, userEditingTheObject })) {
      console.log(
        'DbDocumentService.uploadIfNotEditingStaleObject: Could not update this object because the copy in the db is more recent than your copy.',
      );
      return false;
    }
    await lastValueFrom(this.upload({ obj: requestedUpdate, mode: 'update' }));
    console.log('DbDocumentService.uploadIfNotEditingStaleObject: Object updated!');
    return true;
  }

  ///////////////////////////////////////////////////////////////////////
  // Upload Transaction
  ///////////////////////////////////////////////////////////////////////

  public async runDBTransaction(dbTransaction: DBTransaction<T>): Promise<void> {
    const promise = this.db.firestore
      .runTransaction(async (transaction: firebase.firestore.Transaction) => {
        await this.getTransactionUpdateFn(transaction, dbTransaction.actions);
      })
      .catch((err) => {
        console.error(`DbDocumentService.runDBTransaction:${this.getLoggingInfo()}: Error`, { dbTransaction });
        console.error('err');
        console.error(err);
        throw err;
      })
      .finally(() => {
        console.log(`DbDocumentService.runDBTransaction:${this.getLoggingInfo()}: Complete`, { dbTransaction });
      });

    return promise;
  }

  private async getTransactionUpdateFn(
    transaction: firebase.firestore.Transaction,
    actions: DBAction<T>[],
    index = 0,
  ): Promise<firebase.firestore.Transaction> {
    if (isNil(actions) || actions.length === 0 || index >= actions.length) {
      return transaction;
    }

    const updatedTransaction = await this.doDBAction(transaction, actions[index]);
    return this.getTransactionUpdateFn(updatedTransaction, actions, index + 1);
  }

  private async doDBAction(transaction: firebase.firestore.Transaction, action: DBAction<T>): Promise<firebase.firestore.Transaction> {
    if (action instanceof DBCreateDoc) {
      const docId = this.getUploadId(action.obj, isNilOrDefaultDocId(action.obj.getId()));
      const docRef = await this.docRef(docId).toPromise();
      const serializedValue = await this.getSerializedObjWithGenericFields(action.obj, 'create').toPromise();
      return transaction.set(docRef.ref, serializedValue);
    }

    if (action instanceof DBDeleteDoc) {
      const docId = action.obj.getId();
      const docRef = await this.docRef(docId).toPromise();
      return transaction.delete(docRef.ref);
    }

    if (action instanceof DBUploadExistingDoc) {
      const docId = action.obj.getId();
      const docRef = await this.docRef(docId).toPromise();
      const serializedValue = await this.getSerializedObjWithGenericFields(action.obj, 'update').toPromise();
      return transaction.update(docRef.ref, serializedValue);
    }
    console.error(action);
    throw new Error('doDBAction: Unknown action type');
  }

  ///////////////////////////////////////////////////////////////////////
  // Delete
  ///////////////////////////////////////////////////////////////////////

  /**
   * Delete the document at the specified id.
   *
   * @param docId Document Id to delete
   * @param batch If specified, then add this delete operation to the specified batch
   */
  public delete(docId: string, batch?: firebase.firestore.WriteBatch): Observable<void> {
    const observable = this.docRef(docId).pipe(
      switchMap((docRef, _) => {
        if (batch !== undefined) {
          return from(batch.delete(docRef.ref).commit());
        }
        return from(docRef.delete());
      }),
      catchError((err, caught) => {
        console.error(`DbDocumentService.delete:${this.getLoggingInfo()}, docId=${docId}: Error`);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
      finalize(() => console.log(`DbDocumentService.delete:${this.getLoggingInfo()}, docId=${docId}: Complete`)),
    );

    return observable;
  }

  /**
   * Delete the document at the specified id.
   *
   * @param docId Document Id to delete
   * @param batch If specified, then add this delete operation to the specified batch
   */
  public async deleteDocs(docIds: string[]): Promise<void> {
    const docRefs = await Promise.all(
      docIds.map((docId) =>
        this.docRef(docId)
          .pipe(map((z) => z.ref))
          .toPromise(),
      ),
    );

    const promise = this.db.firestore
      .runTransaction(async (transaction: firebase.firestore.Transaction) => {
        for (const docRef of docRefs) {
          transaction.delete(docRef);
        }
      })
      .catch((err) => {
        console.error(`DbDocumentService.deleteDocs:${this.getLoggingInfo()}: Error`, { docIds });
        console.error('err');
        console.error(err);
        throw err;
      })
      .finally(() => {
        console.log(`DbDocumentService.deleteDocs:${this.getLoggingInfo()}: Complete`, { docIds });
      });

    return promise;
  }

  ///////////////////////////////////////////////////////////////////////
  // Logging
  ///////////////////////////////////////////////////////////////////////

  /**
   * Return parameters appending to beginning of some logging statements for
   * debugging purposes
   */
  protected getLoggingInfo(): string {
    return `collection=${this.objGenerator.getSchema().getCollection(this.orgId || 'ORG_ID')}`;
  }

  ///////////////////////////////////////////////////////////////////////
  // Auth Service
  ///////////////////////////////////////////////////////////////////////

  protected getInjectedAuthService(): AuthService {
    return this.authService;
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Object from Firebase
  ///////////////////////////////////////////////////////////////////////

  /**
   * Convert the given json object from Firebase into an object of the appropriate type T
   *
   * @param obj
   * @param defaultValue
   */
  protected getObjectFromFirebase(
    obj: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>,
    defaultValue?: T,
  ): T | undefined {
    if (isNil(obj) || !obj.exists || isNil(obj.id) || isNil(obj.data())) {
      console.log(`${this.getLoggingInfo()}: Object does not exist. Returning undefined.`);
      return defaultValue; // this.objGenerator.deserialize({}) as T;
    }
    const data = obj.data();
    if (Object.keys(data).includes(DBDocSchema.id)) {
      console.error(`${this.getLoggingInfo()}:
            Document has reserved key id with value: ${data[DBDocSchema.id]}. Overwriting this value`);
    }
    data[DBDocSchema.id] = obj.id;

    return this.objGenerator.deserialize(data);
  }

  ///////////////////////////////////////////////////////////////////////
  // Firebase Reference
  ///////////////////////////////////////////////////////////////////////

  protected getCollectionName(orgId: string): string {
    return this.objGenerator.getSchema().getCollection(orgId);
  }

  /**
   * Return a string representing the collection.
   */
  protected getCollection(): Observable<string> {
    const observable = this.authService.getOrgId().pipe(
      map((orgId) => {
        // Save orgId for logging purposes
        this.orgId = orgId;

        return this.getCollectionName(orgId);
      }),
      catchError((err, caught) => {
        console.error(`DbDocumentService.collectionStringNoQuery: ${this.getLoggingInfo()}: Error`);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
    );
    return observable;
  }

  protected collectionRefWithQuery(query: DBQuery[] = [], orderBy?: DBOrderBy, limit?: number): Observable<AngularFirestoreCollection> {
    const observable = this.getCollection().pipe(
      map((collectionStringNoQuery, _) =>
        this.db.collection(collectionStringNoQuery, (ref) => {
          let builder: firebase.firestore.Query = ref;

          for (const queryItem of query) {
            builder = builder.where(queryItem.fieldPath, queryItem.opStr, queryItem.value);
          }

          if (orderBy !== undefined) {
            builder = builder.orderBy(orderBy.fieldPath, orderBy.directionStr);
          }

          if (limit !== undefined) {
            builder = builder.limit(limit);
          }

          return builder;
        }),
      ),
      catchError((err, caught) => {
        console.error(`DbDocumentService.collectionRef: ${this.getLoggingInfo()}: Error`);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
    );

    return observableTakeOne(observable, true);
  }

  protected docRef(docId: string): Observable<AngularFirestoreDocument> {
    const observable = this.collectionRefWithQuery([], undefined, undefined).pipe(
      map((collectionRef, _) => collectionRef.doc(docId)),
      catchError((err, caught) => {
        console.error(`DbDocumentService.docRef:${this.getLoggingInfo()}, docId=${docId}: Error`);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
    );
    return observableTakeOne(observable, true);
  }

  ///////////////////////////////////////////////////////////////////////
  // Upload Id
  ///////////////////////////////////////////////////////////////////////

  protected getUploadId(obj: T, generateId: boolean): string {
    if (!generateId) {
      try {
        const id = (obj as any).getId()!;
        if (id === DBDocSchema.GenericDefaults.id) {
          console.error('User Error: generateId is false, but no id is provided', { generateId });
          throw new Error('User Error: Cant upload an object with default id');
        }
        return id;
      } catch (exception) {
        console.error(`DbDocumentService.getUploadId: ${this.getLoggingInfo()}, generateId=${generateId}: Error`);
        console.error('DbDocumentService.getUploadId: obj');
        console.error(obj);
        console.error('DbDocumentService.getUploadId: exception');
        console.error(exception);
        throw new Error(`DbDocumentService.getUploadId: generateId=${generateId}: Unable to getId()`);
      }
    }
    return this.createId();
  }

  ///////////////////////////////////////////////////////////////////////
  // Serialization
  ///////////////////////////////////////////////////////////////////////

  protected getSerializedObjWithGenericFields(obj: T, mode: UploadMode): Observable<any> {
    const uploadDictionary = (obj as any).serialize();
    return this.addGenericFields(uploadDictionary, mode);
  }

  protected addGenericFields(uploadDictionary: any, mode: UploadMode): Observable<any> {
    const observable = this.authService.getUserId().pipe(
      map((userId, _) => {
        switch (mode) {
          case 'create': {
            uploadDictionary[DBDocSchema.createTime] = firebase.firestore.FieldValue.serverTimestamp();
            uploadDictionary[DBDocSchema.createdByUserId] = userId;
            break;
          }
          case 'update': {
            // Upload will be set with merge = true, so remove the create generic fields.
            // This is required if the create timestamp has nonzero nonosecond precision,
            // since in that case upload will fail for this reason.
            delete uploadDictionary[DBDocSchema.createTime];
            delete uploadDictionary[DBDocSchema.createdByUserId];

            uploadDictionary[DBDocSchema.lastUploadTime] = firebase.firestore.FieldValue.serverTimestamp();
            uploadDictionary[DBDocSchema.lastModifiedByUserId] = userId;
            break;
          }
        }
        return uploadDictionary;
      }),
      catchError((err, caught) => {
        console.error(`DbDocumentService.getUploadDictionaryWithGenericFields:${this.getLoggingInfo()}, mode=${mode}: Error`);
        console.error('DbDocumentService.getUploadDictionaryWithGenericFields: uploadDictionary');
        console.error(uploadDictionary);
        console.error('DbDocumentService.getUploadDictionaryWithGenericFields: err');
        console.error(err);
        console.error('DbDocumentService.getUploadDictionaryWithGenericFields: caught');
        console.error(caught);
        throw err;
      }),
    );

    return observable;
  }
}
