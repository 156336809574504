import {cloneDeep} from 'lodash';

import {DBMatchRuleDocPath} from '../../../../core/db-matcher/db-match-rule/db-match-rules/db-match-rule-doc-path/db-match-rule-doc-path';
import {DBDocSchema} from '../../../../generic/db-doc/db-doc-schema';
import {GenericDisplayable} from '../../../../generic/displayable/generic-displayable';
import {AutomationAction} from '../../../automation-action/generic/automation-action/automation-action';
import {AutomationName} from '../../automation-name';

import {AutomationConstructor} from './automation-constructor';

export abstract class Automation extends GenericDisplayable {
  protected actions!: AutomationAction[];

  protected triggerOn!: DBMatchRuleDocPath;

  protected type!: AutomationName;

  constructor(parameters: AutomationConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract Automation');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getActions(): AutomationAction[] {
    return cloneDeep(this.actions);
  }

  public getTriggerOn(): DBMatchRuleDocPath {
    return cloneDeep(this.triggerOn);
  }

  public getType(): AutomationName {
    return cloneDeep(this.type);
  }
}
