import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';

import { AutomationOnCommunicationComplete } from '@pwp-common';

import { AccordionWizardModule } from '../../../../../../components/generic/accordion-wizard/accordion-wizard.module';
import { AuditEntryPipe } from '../../../../../../components/generic/settings/common/audit-entry/audit-entry.pipe';
import { SettingsModule } from '../../../../../../components/generic/settings/settings.module';
import { UploadMode } from '../../../../../../services/generic/interfaces';
import { AutomationEndpointService } from '../../../../services/automation-endpoint.service';
import { AutomationService } from '../../../../services/automation.service';
import { AutomationTriggerAutocompleteComponent } from '../automation-trigger-autocomplete/automation-trigger-autocomplete.component';

import { AutomationEditorForm, AutomationEditorOutput } from './editor-output/automation-editor-output';
import { createAutomationEditorOutput } from './editor-output/create-automation-editor-output/create-automation-editor-output';
import { createAutomationFromEditorOutput } from './editor-output/create-automation-from-editor-output/create-automation-from-editor-output';

@Component({
  selector: 'app-automation-editor',
  standalone: true,
  imports: [
    AccordionWizardModule,
    AuditEntryPipe,
    ButtonModule,
    CommonModule,
    EditorModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    TranslocoModule,
    ToastModule,
    AutomationTriggerAutocompleteComponent,
    SettingsModule,
  ],
  templateUrl: './automation-editor.component.html',
  styleUrls: ['./automation-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationEditorComponent implements OnInit {
  private readonly automationService = inject(AutomationService);

  private readonly automationEndpointService = inject(AutomationEndpointService);

  private readonly dialogRef = inject(DynamicDialogRef, { optional: true });

  private readonly fb = inject(FormBuilder);

  public readonly form = this.fb.group<AutomationEditorForm>(this.getInitialFormConfig());

  @Output() public readonly saved = new EventEmitter<void>();

  @Input() public automation?: AutomationOnCommunicationComplete;

  @Input() public mode: UploadMode = 'create';

  private getInitialFormConfig(): AutomationEditorForm {
    return {
      display: this.fb.group({
        name: this.fb.control('', Validators.required),
        description: this.fb.control(''),
      }),
      action: this.fb.group({
        subject: this.fb.control('', [Validators.required]),
        message: this.fb.control('', Validators.required),
      }),
      trigger: this.fb.group({
        paths: this.fb.control([], Validators.required),
      }),
    };
  }

  private setAutomationValues(): void {
    this.form.setValue(createAutomationEditorOutput(this.automation));
  }

  public ngOnInit(): void {
    if (this.automation) {
      this.setAutomationValues();
    }
  }

  public saveAutomation = async (): Promise<void> => {
    // @Todo: switch to using the API when it's available
    const automation = createAutomationFromEditorOutput(
      this.automation?.getId() ?? this.automationService.createId(),
      this.form.value as AutomationEditorOutput,
    );

    await this.automationEndpointService.createAutomation(automation);
    this.dialogRef?.close(automation);
  };

  public refreshData = async () => this.saved.emit();

  public isValid = () => this.form.valid;

  public resetForm = () => {
    if (this.automation) {
      this.setAutomationValues();
    } else {
      this.form.reset(new FormGroup(this.getInitialFormConfig()).value);
    }
  };
}
