<ng-container *transloco="let t; read: 'data-table'">
  <p-table
    #dt
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    dataKey="id"
    responsiveLayout="scroll"
    sortMode="single"
    styleClass="p-datatable-gridlines p-datatable-striped"
    [columns]="selectedCols"
    [customSort]="true"
    [globalFilterFields]="globalFilterFields"
    [loading]="loading"
    [paginator]="true"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [rows]="50"
    [rowsPerPageOptions]="[25, 50, 100, 500]"
    [showCurrentPageReport]="true"
    [value]="tableRows | async"
    (sortFunction)="sortTable($event)"
  >
    <!-- Filters -->
    <ng-template pTemplate="caption">
      <legend *ngIf="title">{{ title }}</legend>
      <app-data-table-caption
        [actionsTemplate]="actionsTemplate"
        [cols]="cols"
        [download]="download"
        [refreshClicked]="refreshClicked"
        [rightActionsTemplate]="rightActionsTemplate"
        [table]="dt"
        [translationScope]="translationScope"
        [(selectedCols)]="selectedCols"
      >
      </app-data-table-caption>
    </ng-template>

    <!-- Header -->
    <ng-template *transloco="let dataScopedT; read: translationScope" pTemplate="header" let-columns>
      <tr>
        <th *ngIf="expansionRow !== undefined" style="width: 3em"></th>

        <ng-container *ngFor="let col of columns">
          <ng-container
            *ngIf="columnDefinitions[col.field]?.header as headerDefinition; else headerTranslation"
            [ngTemplateOutlet]="headerDefinition.templateRef"
          ></ng-container>
          <ng-template #headerTranslation>
            <th pReorderableColumn pSortableColumn="{{ col.field }}">
              {{ dataScopedT(col.header) }}
              <p-sortIcon field="{{ col.field }}"></p-sortIcon>
            </th>
          </ng-template>
        </ng-container>

        <th *ngIf="rowIconButtons && rowIconButtons.length > 0" style="width: 3em"></th>
      </tr>
    </ng-template>

    <!-- Display Rows -->
    <ng-template
      *transloco="let unscopedT"
      pTemplate="body"
      let-columns="columns"
      let-expanded="expanded"
      let-rowData
      let-rowIndex="rowIndex"
    >
      <tr [ngClass]="rowData.alertType" [pReorderableRow]="rowIndex">
        <td *ngIf="expansionRow !== undefined" class="ui-resizable-column">
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
          </a>
        </td>

        <ng-container *ngFor="let col of columns">
          <ng-container
            *ngIf="columnDefinitions[col.field]?.cell as cellDefinition; else rowDataCell"
            [ngTemplateOutlet]="cellDefinition.templateRef"
            [ngTemplateOutletContext]="{ $implicit: rowData, rowIndex }"
          ></ng-container>
          <ng-template #rowDataCell>
            <td class="ui-resizable-column row-toggle-cursor" [pRowToggler]="rowData">
              <app-row-cell [col]="col" [rowData]="rowData"></app-row-cell>
            </td>
          </ng-template>
        </ng-container>

        <td *ngIf="rowIconButtons && rowIconButtons.length > 0" class="ui-resizable-column">
          <ng-container *ngFor="let button of rowIconButtons">
            <button
              pButton
              class="p-button-rounded p-button-danger p-button-text"
              type="button"
              (click)="onRowIconButtonClick(rowData, rowIndex, button.name)"
            >
              <i class="{{ button.icon }}"></i>
            </button>
          </ng-container>
        </td>
      </tr>
    </ng-template>

    <!-- Expansion -->
    <ng-template *ngIf="expansionRow !== undefined" pTemplate="rowexpansion" let-columns="columns" let-rowData>
      <!--
        Note: index is intentionally omitted should not be trusted for looping over data
        Rows can be filtered and sorted, which would change the index of rows and can cause mismatch with expected index
        Instead, use rowData object to identify the row by a unique identifier
      -->
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <ng-container
            [ngTemplateOutlet]="expansionRow"
            [ngTemplateOutletContext]="{ $implicit: rowData, rowData: rowData }"
          ></ng-container>
        </td>
      </tr>
    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="rowIconButtons && rowIconButtons.length > 0 ? columns.length + 1 : columns.length">
          {{ t('emptyMessage') }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
