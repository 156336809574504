import { generateColumnId } from './generate-column-id';

export const parseColumns = (columns: unknown, prefix: string): Record<string, unknown> =>
  Object.entries(columns).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [generateColumnId(key, prefix)]: value,
    }),
    {},
  );
