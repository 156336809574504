import { RouterModule, Routes } from '@angular/router';

import { CommunicationLogsTableComponent } from './components/communication-logs/communication-logs-table/communication-logs-table.component';
import { OrgDataSelectAndEditComponent } from './components/config/org-data-select-and-edit/org-data-select-and-edit.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AdminGuard } from './guards/admin/admin.guard';
import { VoicemailGuard } from './guards/voicemail/voicemail.guard';
import { authRoutes } from './modules/auth/auth.routes';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';
import { automationRoutes } from './modules/automation/automation.routes';
import { AdminAnalyticsComponent } from './pages/admin-analytics/admin-analytics.component';
import { ChatComponent } from './pages/chat/chat.component';
import { EventRequestsComponent } from './pages/event-requests/event-requests.component';
import { EventSelectorComponent } from './pages/event-selector/event-selector.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { IncomingChatComponent } from './pages/incoming-chat/incoming-chat.component';
import { ModifyScheduleComponent } from './pages/modify-schedule/modify-schedule.component';
import { OrgSettingsComponent } from './pages/org-settings/org-settings.component';
import { ServiceExceptionsComponent } from './pages/service-exceptions/service-exceptions.component';
import { SupportComponent } from './pages/support/support.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UsersAdminComponent } from './pages/users-admin/users-admin.component';

// Route Configuration
export const routes: Routes =
  // prettier-ignore
  [
    ...authRoutes,
    { path: 'incomingChat',            component: IncomingChatComponent  },
    {
      path: '',
      canActivate: [AuthGuard],
      loadComponent: () => import('./layouts/base-layout/base-layout.component').then((m) => m.BaseLayoutComponent),
      children: [
        { path: 'automation',            children: automationRoutes },
        { path: 'logout',                component: LogoutComponent },
        { path: 'user-profile',          component: UserProfileComponent },
        { path: 'chat',                  component: ChatComponent },
        { path: 'events-calendar',       component: EventSelectorComponent },
        { path: 'call-logs',             component: CommunicationLogsTableComponent },
        { path: 'inbox',                 component: InboxComponent,                canActivate: [VoicemailGuard]},
        { path: 'event-requests',        component: EventRequestsComponent,        canActivate: [AdminGuard]},
        { path: 'modify-schedule',       component: ModifyScheduleComponent,       canActivate: [AdminGuard]},
        { path: 'users',                 component: UsersAdminComponent,           canActivate: [AdminGuard]},
        { path: 'stats',                 component: AdminAnalyticsComponent,       canActivate: [AdminGuard]},
        { path: 'support',               component: SupportComponent,              canActivate: [AdminGuard]},
        { path: 'serviceExceptions',     component: ServiceExceptionsComponent,    canActivate: [AdminGuard]},
        { path: 'orgSettings',           component: OrgSettingsComponent,          canActivate: [AdminGuard]},
        { path: 'communicationWorkflow', component: OrgSettingsComponent,          canActivate: [AdminGuard]},
        // PWP Admin Options
        { path: 'org-select-and-edit',   component: OrgDataSelectAndEditComponent, canActivate: [AdminGuard]},
      ],
    }
];
export const routing = RouterModule.forRoot(routes, {
  relativeLinkResolution: 'legacy',
});
