import { SortEvent } from 'primeng/api';

import { RowCell } from '../row-cell';

const getCellValue = (cell: RowCell<unknown> | unknown) => (cell instanceof RowCell ? cell.sortValue : cell);

export const handleSortEvent = (sortEvent: SortEvent) => {
  sortEvent.data.sort((data1, data2) => {
    const value1 = getCellValue(data1[sortEvent.field]);
    const value2 = getCellValue(data2[sortEvent.field]);

    let result = null;

    if (value1 == null && value2 != null) {
      result = -1;
    } else if (value1 != null && value2 == null) {
      result = 1;
    } else if (value1 == null && value2 == null) {
      result = 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    }

    return sortEvent.order * result;
  });
};
