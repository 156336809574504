import type {ValidationResult} from 'joi';
import {cloneDeep} from 'lodash';

import {AutomationAction} from '../../../automation-action/generic/automation-action/automation-action';
import {AutomationName} from '../../automation-name';
import {Automation} from '../../generic/automation/automation';
import {AutomationSchema} from '../../generic/automation/automation-schema';

import {AutomationOnCommunicationCompleteConstructor} from './automation-on-communication-complete-constructor';
import {AutomationOnCommunicationCompleteSchema} from './automation-on-communication-complete-schema';

export class AutomationOnCommunicationComplete extends Automation {
  protected formId?: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AutomationOnCommunicationCompleteConstructor) {
    super({
      ...parameters,
      [AutomationSchema.type]: AutomationName.onCommunicationComplete,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): AutomationOnCommunicationComplete {
    return new AutomationOnCommunicationComplete(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AutomationOnCommunicationCompleteSchema {
    return new AutomationOnCommunicationCompleteSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AutomationOnCommunicationComplete.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getFormId(): string | undefined {
    return cloneDeep(this.formId);
  }

  public getActions(): AutomationAction[] {
    return cloneDeep(this.actions);
  }
}
