<app-data-table
  [cols]="cols"
  [download]="downloadReport"
  [expansionRow]="expansion"
  [loading]="loading$ | async"
  [rightActionsTemplate]="dateRangeSelect"
  [selectedCols]="cols"
  [tableRows]="tableRows$"
  [translationScope]="'form-submission-reports'"
>
  <ng-template #expansion let-data>
    <survey *ngIf="stylesLoaded$ | async" [model]="data.surveyModel"></survey>
  </ng-template>
</app-data-table>

<ng-template #dateRangeSelect>
  <app-date-range-select [formControl]="dateRange"></app-date-range-select>
</ng-template>
