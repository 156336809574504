<mat-tab-group *transloco="let t; read: 'admin-analytics'">
  <mat-tab [label]="t('overview.tabLabel')">
    <ng-template matTabContent>
      <app-admin-overview-dashboard></app-admin-overview-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="t('burnoutPrevention.tabLabel')">
    <ng-template matTabContent>
      <app-admin-burnout-dashboard></app-admin-burnout-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="orgData !== undefined" [label]="t('shiftHours.tabLabel')">
    <ng-template matTabContent>
      <app-admin-hours-dashboard [orgData]="orgData"></app-admin-hours-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="t('usage.tabLabel')">
    <ng-template matTabContent>
      <app-admin-usage-dashboard></app-admin-usage-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="t('reports.tabLabel')">
    <ng-template matTabContent>
      <app-report></app-report>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="t('forms.tabLabel')">
    <ng-template matTabContent>
      <app-form-submission-reports></app-form-submission-reports>
    </ng-template>
  </mat-tab>
</mat-tab-group>
