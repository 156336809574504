import { inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../../../services/user/auth/auth.service';
import { RolesService } from '../../../../services/user/roles/roles.service';
import { getUserHomepage } from '../../helpers/get-user-homepage/get-user-homepage';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  private readonly authService = inject(AuthService);

  private readonly rolesService = inject(RolesService);

  private readonly router = inject(Router);

  private createRedirectUrl(): Observable<UrlTree> {
    return this.rolesService.getRoles().pipe(map((userRoles) => this.router.createUrlTree([getUserHomepage(userRoles)])));
  }

  public canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn().pipe(switchMap((isLoggedIn) => (isLoggedIn ? this.createRedirectUrl() : of(true))));
  }
}
