import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject, combineLatest, filter, map, switchMap } from 'rxjs';

import { AutomationOnCommunicationComplete } from '@pwp-common';

import { makePTableCols } from '../../../../common/p-table/make-p-table-cols';
import { DataTableModule } from '../../../../components/generic/data-table/data-table.module';
import { CallListService } from '../../../../services/call/call-list/call-list.service';
import { CommunicationWorkflowService } from '../../../../services/communication/communication-workflow/communication-workflow.service';
import { ConversationConfigService } from '../../../../services/conversation/conversation-config/conversation-config.service';
import { AutomationService } from '../../services/automation.service';

import { AutomationEditorComponent } from './components/automation-editor/automation-editor.component';
import { createAutomationTableRow } from './helpers/create-automation-table-row/create-automation-table-row';

@UntilDestroy()
@Component({
  selector: 'app-automation-table-editor',
  standalone: true,
  imports: [AutomationEditorComponent, ButtonModule, CommonModule, DataTableModule, MessagesModule, ToastModule, TranslocoModule],
  providers: [DialogService],
  templateUrl: './automation-table-editor.component.html',
  styleUrls: ['./automation-table-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationTableEditorComponent {
  private readonly automationService = inject(AutomationService);

  private readonly callListService = inject(CallListService);

  private readonly communicationWorkflowService = inject(CommunicationWorkflowService);

  private readonly conversationConfigService = inject(ConversationConfigService);

  private readonly dialogService = inject(DialogService);

  private readonly refresh$ = new BehaviorSubject<void>(null);

  public readonly cols = makePTableCols({
    columns: ['displayName', 'description', 'triggerOn', 'summary'],
    translationScope: 'automation-table-editor',
  });

  public readonly tableRows$ = this.refresh$.pipe(
    switchMap(() =>
      combineLatest({
        automations: this.automationService.getDocsArray(),
        callLists: this.callListService.getDocs(),
        communicationWorkflows: this.communicationWorkflowService.getDocs(),
        conversationConfigs: this.conversationConfigService.getDocs(),
      }),
    ),
    map(({ automations, ...params }) =>
      automations.map((automation: AutomationOnCommunicationComplete) => createAutomationTableRow({ automation, ...params })),
    ),
  );

  public updateWithSuccess(): void {
    this.refresh$.next();
  }

  public openNewAutomationDialog(): void {
    this.dialogService
      .open(AutomationEditorComponent, {
        height: '100%',
        width: 'clamp(32rem, 75%, 64rem)',
      })
      .onClose.pipe(untilDestroyed(this), filter(Boolean))
      .subscribe(() => this.updateWithSuccess());
  }
}
