import { inject, Injectable } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { RolesService } from '../../../../services/user/roles/roles.service';
import { getUserHomepage } from '../../helpers/get-user-homepage/get-user-homepage';

import { ResetPasswordInput } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly auth = inject(Auth);

  private readonly rolesService = inject(RolesService);

  private readonly route = inject(ActivatedRoute);

  private readonly router = inject(Router);

  public async signIn(email: string, password: string): Promise<void> {
    await signInWithEmailAndPassword(this.auth, email, password);

    const { returnURL } = this.route.snapshot.queryParams;

    if (returnURL) {
      await this.router.navigateByUrl(returnURL);
    } else {
      const userRoles = await firstValueFrom(this.rolesService.getRoles());
      await this.router.navigate([getUserHomepage(userRoles)]);
    }
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  public async verifyPasswordResetCode(oobCode: string): Promise<string> {
    return verifyPasswordResetCode(this.auth, oobCode);
  }

  public async resetPassword({ oobCode, userEmail, newPassword }: ResetPasswordInput): Promise<void> {
    await confirmPasswordReset(this.auth, oobCode, newPassword);

    await this.signIn(userEmail, newPassword);
  }
}
