import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';

import { handleSortEvent } from '../../../../common/p-table/handle-sort-event/handle-sort-event';
import { PTableCol } from '../../../../common/p-table/p-table-col';
import { DataTableRow } from '../common/data-table-row';
import { getGlobalFilterFields } from '../common/global-filter-fields/get-global-filter-fields';
import { DataTableColumnDefDirective } from '../data-table-column-def/data-table-column-def.directive';

@UntilDestroy()
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0,
        }),
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        }),
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class DataTableComponent<TableRow extends DataTableRow> implements OnChanges, AfterContentInit {
  @ContentChildren(DataTableColumnDefDirective) private readonly columnDefinitionsQueryList!: QueryList<DataTableColumnDefDirective>;

  public columnDefinitions: Record<string, DataTableColumnDefDirective> = {};

  public globalFilterFields = [];

  ///////////////////////////////////////////////////////////////////////
  // Inputs & Outputs
  ///////////////////////////////////////////////////////////////////////
  @Input() public title = undefined;

  @Input() public translationScope = 'data-table';

  @Input() public loading = false;

  @Input() public cols: PTableCol[] = [];

  @Input() public selectedCols: PTableCol[];

  @Input() public tableRows: Observable<TableRow[]> = new Observable<TableRow[]>();

  @Input() public expansionRow: TemplateRef<unknown>;

  @Input() public actionsTemplate: TemplateRef<unknown>;

  @Input() public rightActionsTemplate: TemplateRef<unknown>;

  @Input() public download?: (table: Table) => void | Promise<void>;

  @Input() public refreshClicked: () => void;

  @Input() public rowIconButtons: { icon: string; name: string }[] = [];

  @Output() public readonly rowIconButtonClicked = new EventEmitter<{ rowData: TableRow; index: number; ariaLabel: string }>();

  public ngOnChanges() {
    this.globalFilterFields = getGlobalFilterFields(this.cols);
  }

  ///////////////////////////////////////////////////////////////////////
  // Sorting
  ///////////////////////////////////////////////////////////////////////
  public sortTable(sortEvent: SortEvent) {
    handleSortEvent(sortEvent);
  }

  public onRowIconButtonClick(rowData: TableRow, index: number, ariaLabel: string) {
    this.rowIconButtonClicked.emit({ rowData, index, ariaLabel });
  }

  public ngAfterContentInit(): void {
    this.columnDefinitions = this.columnDefinitionsQueryList.reduce(
      (acc, columnDef) => ({
        ...acc,
        [columnDef.name]: columnDef,
      }),
      {},
    );
  }
}
