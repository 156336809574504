import {AllDataEvent} from './all-data-event';
import {AllDataUserDisplay} from '../../user/all-data-user/all-data-user-display';
import {cloneDeep, isNil} from 'lodash';
import {getEventColorDisplayName} from './get-event-color-display-name';
import {AssignedUserType} from '../event-data/enums';
import {createEvent, DateArray} from 'ics';
import {OrgData} from '../../org/org-data/org-data';
import {displayTime} from '../../../helper/time/display-time/display-time';

/**
 * This class transforms AllDataEvent(), into a format suitable for displaying.
 */
export class AllDataEventDisplay {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  // Data used to initialize class
  public allDataEvent!: AllDataEvent;
  public orgData: OrgData;

  // Start and End Time
  id = '';
  startTime = '';
  endTime = '';
  type = '';
  color = '';
  public numRequests = 0;

  // Primary/Backup User Display info
  public primaryAllDataUserDisplay: AllDataUserDisplay | undefined;
  public backupAllDataUserDisplay: AllDataUserDisplay | undefined;
  public lastModifiedBy: AllDataUserDisplay | undefined;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(allDataEvent: AllDataEvent, orgData: OrgData) {
    this.allDataEvent = cloneDeep(allDataEvent);
    this.orgData = cloneDeep(orgData);

    // Getters make a copy, so make local vars to avoid making many copies
    const eventData = this.allDataEvent.getEventData();
    const allDataUserMap = this.allDataEvent.getAllDataUserMap();

    // Basic Event Properties

    this.numRequests = this.allDataEvent.getRequests()?.length ?? 0;

    if (isNil(eventData)) {
      return;
    }

    this.id = eventData.getId();
    this.startTime = displayTime(eventData.getStart(), orgData.getTimezone());
    this.endTime = displayTime(eventData.getEnd(), orgData.getTimezone());
    this.color = getEventColorDisplayName(eventData.getColor());

    // Set Type
    for (const eventType of this.orgData.getEventTypes()) {
      if (eventType.getInternalName() === eventData.getType()) {
        this.type = eventType.getDisplayName();
      }
    }

    // Primary User

    const primaryAllDataUser = allDataUserMap.get(eventData.getAssignedUserId() || '');
    if (primaryAllDataUser !== undefined) {
      this.primaryAllDataUserDisplay = new AllDataUserDisplay(primaryAllDataUser);
    }
    console.log('E2E debug: allDataUserMap =', allDataUserMap, allDataUserMap?.size);
    console.log('E2E debug: eventData.getAssignedUserId() =', eventData?.getAssignedUserId());
    console.log('E2E debug: primaryAllDataUser =', primaryAllDataUser);
    // Backup User

    const backupAllDataUser = allDataUserMap.get(eventData.getAssignedBackupUserId() || '');
    if (backupAllDataUser !== undefined) {
      this.backupAllDataUserDisplay = new AllDataUserDisplay(backupAllDataUser);
    }
    console.log('E2E debug: eventData.getAssignedBackupUserId() =', eventData?.getAssignedBackupUserId());
    console.log('E2E debug: backupAllDataUser =', backupAllDataUser);

    // Last Modified By
    const lastModifiedByUser = allDataUserMap.get(eventData.getLastModifiedByUserId() || '');
    if (lastModifiedByUser !== undefined) {
      this.lastModifiedBy = new AllDataUserDisplay(lastModifiedByUser);
    }
  }

  public getICS(assignedUserType: AssignedUserType, organizerEmail: string, description?: string): string | undefined {
    const eventData = this.allDataEvent.getEventData();

    if (isNil(eventData)) {
      return undefined;
    }

    const allDataUserMap = this.allDataEvent.getAllDataUserMap();
    const allDataUser = allDataUserMap.get(eventData.getUser(assignedUserType) || '');

    /* eslint-disable */
    const startUTC = eventData.getStart().utc();
    const startArray: DateArray = [startUTC.year(), startUTC.month() + 1, startUTC.date(), startUTC.hour(), startUTC.minute()];

    const endUTC = eventData.getEnd().utc();
    const endArray: DateArray = [endUTC.year(), endUTC.month() + 1, endUTC.date(), endUTC.hour(), endUTC.minute()];
    /* eslint-enable */

    const {value} = createEvent({
      start: startArray,
      end: endArray,
      startInputType: 'utc',
      endInputType: 'utc',
      title: this.type,
      description: description || '',
      location: '', // you can use a link here if it is online
      status: 'CONFIRMED',
      organizer: {name: organizerEmail, email: organizerEmail},
      attendees: [
        {
          name: allDataUser?.userData?.getDisplayName() || '',
          email: allDataUser?.userData?.getNotificationEmail() || '',
          rsvp: true,
          partstat: 'ACCEPTED',
          role: 'REQ-PARTICIPANT',
        },
      ],
      method: 'REQUEST',
      productId: 'helplinesoftware.com',
    });

    if (value === undefined) {
      throw new Error('Unable to create event');
    }

    return value;
  }

  /////////////////////////////////////////////////////////////////////////////
  // CSS
  /////////////////////////////////////////////////////////////////////////////

  public getCSSClass(): string {
    // No special CSS
    return 'null';
  }
}
