import moment from 'moment-timezone';
import {DBAction} from '../../../db/transaction/actions/db-action';
import {DBTransaction} from '../../../db/transaction/db-transaction';
import {DBTransactionType} from '../../../db/transaction/db-transaction-type';
import {OrgData} from '../../org/org-data/org-data';
import {AllDataUser} from '../../user/all-data-user/all-data-user';
import {AllDataUserDisplay} from '../../user/all-data-user/all-data-user-display';

import {getEventColorDisplayName} from '../all-data-event/get-event-color-display-name';
import {EventData} from '../event-data/event-data';
import {displayTime} from '../../../helper/time/display-time/display-time';

export class EventDataTransactionDisplay extends DBTransaction<EventData> {
  allDataUserMap: Map<string, AllDataUser>;
  // Start and End Time
  startTime: string;
  endTime: string;
  id = '';
  type = '';
  color = '';

  primaryUserDisplayName = '';
  primaryUserEmail = '';
  primaryUserPhone = '';

  backupUserDisplayName = '';
  backupUserEmail = '';
  backupUserPhone = '';

  ////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////

  constructor(
    allDataUserMap: Map<string, AllDataUser>,
    orgData: OrgData,
    type: DBTransactionType,
    actions: DBAction<EventData>[],
    displayId: string,
    displayStart: moment.Moment,
    displayEnd: moment.Moment,
    displayAssignedUserId: string | undefined,
    displayAssignedBackupUserId: string | undefined,
    displayColor: string | undefined,
    displayEventType: string
  ) {
    super(type, actions);
    this.allDataUserMap = allDataUserMap;

    this.id = displayId;

    // Basic Event Properties
    this.startTime = displayTime(displayStart, orgData.getTimezone());
    this.endTime = displayTime(displayEnd, orgData.getTimezone());
    this.color = getEventColorDisplayName(displayColor);

    // Set Type
    for (const eventType of orgData.getEventTypes()) {
      if (eventType.getInternalName() === displayEventType) {
        this.type = eventType.getDisplayName();
      }
    }

    // Primary User
    const primaryAllDataUser = allDataUserMap.get(displayAssignedUserId || '');
    if (primaryAllDataUser !== undefined) {
      const primaryAllDataUserDisplay = new AllDataUserDisplay(primaryAllDataUser);
      this.primaryUserDisplayName = primaryAllDataUserDisplay.displayName;
      this.primaryUserEmail = primaryAllDataUserDisplay.email;
      this.primaryUserPhone = primaryAllDataUserDisplay.phone;
      console.log('E2E debug: EventDataTransactionDisplay / primaryUser.displayName', this.primaryUserDisplayName);
      console.log('E2E debug: EventDataTransactionDisplay / primaryUser.email', this.primaryUserEmail);
      console.log('E2E debug: EventDataTransactionDisplay / primaryUser.phone', this.primaryUserPhone);
    }

    // Backup User
    const backupAllDataUser = allDataUserMap.get(displayAssignedBackupUserId || '');
    if (backupAllDataUser !== undefined) {
      const backupAllDataUserDisplay = new AllDataUserDisplay(backupAllDataUser);
      this.backupUserDisplayName = backupAllDataUserDisplay.displayName;
      this.backupUserEmail = backupAllDataUserDisplay.email;
      this.backupUserPhone = backupAllDataUserDisplay.phone;
      console.log('E2E debug: EventDataTransactionDisplay / backupUser.displayName', this.backupUserDisplayName);
      console.log('E2E debug: EventDataTransactionDisplay / backupUser.email', this.backupUserEmail);
      console.log('E2E debug: EventDataTransactionDisplay / backupUser.phone', this.backupUserPhone);
    }
  }
}
