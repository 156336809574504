import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { translate, TranslocoModule } from '@ngneat/transloco';
import { LetModule } from '@ngrx/component';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { BehaviorSubject } from 'rxjs';

import { FormFieldHelpComponent } from '../../../../components/form/form-field-help/form-field-help.component';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    CommonModule,
    FormFieldHelpComponent,
    FormsModule,
    InputTextModule,
    LetModule,
    MessagesModule,
    PasswordModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class LoginComponent {
  private readonly authenticationService = inject(AuthenticationService);

  private readonly fb = inject(FormBuilder);

  private readonly messageService = inject(MessageService);

  // @Todo: handle this by implementing a custom Transloco missing key handler.
  // Need to discuss strategy between returning empty or passing a parameter.
  private readonly knownSignInErrorCodes = new Set(['auth/user-disabled', 'auth/invalid-login-credentials']);

  public readonly form = this.fb.group({
    email: this.fb.control('', { validators: [Validators.required, Validators.email] }),
    password: this.fb.control('', { validators: [Validators.required] }),
    acceptTermsAndPrivacyPolicy: this.fb.control(false, { validators: [Validators.requiredTrue] }),
  });

  public readonly loading$ = new BehaviorSubject(false);

  public readonly showPassword$ = new BehaviorSubject(false);

  public toggleShowPassword(): void {
    this.showPassword$.next(!this.showPassword$.value);
  }

  public async login(): Promise<void> {
    this.loading$.next(true);
    const { email, password } = this.form.value;

    try {
      await this.authenticationService.signIn(email, password);
    } catch (e) {
      const errorCode = this.knownSignInErrorCodes.has(e.code) ? e.code : 'unknown';

      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        detail: translate(`login.signInError.${errorCode}`),
      });
    } finally {
      this.loading$.next(false);
    }
  }
}
