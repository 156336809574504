<ng-container *transloco="let t; read: 'login'">
  <div class="login__header">
    <img alt="Helpline Software" class="login__logo" src="/assets/logos/helpline-software.svg" />
    <h1 class="login__title">{{ t('title') }}</h1>
  </div>

  <form class="login__form">
    <label *ngrxLet="form.controls.email as emailControl" class="login__form-field">
      <span class="login__form-field-label">{{ t('emailField.label') }}</span>

      <input
        pInputText
        class="login__form-field-input"
        type="email"
        [formControl]="emailControl"
        [placeholder]="t('emailField.placeholder')"
      />

      <app-form-field-help [control]="emailControl">
        <ng-container *ngIf="emailControl.errors as emailErrors" form-field-help-errors>
          <ng-container *ngIf="emailErrors.required">{{ t('emailField.requiredError') }}</ng-container>
          <ng-container *ngIf="emailErrors.email">{{ t('emailField.invalidError') }}</ng-container>
        </ng-container>
      </app-form-field-help>
    </label>

    <label *ngrxLet="form.controls.password as passwordControl" class="login__form-field">
      <span class="login__form-field-label">{{ t('passwordField.label') }}</span>

      <span *ngrxLet="showPassword$ as showPassword" class="p-input-icon-right">
        <i
          class="pi"
          role="button"
          [attr.aria-label]="t('passwordField.toggleVisibilityButtonLabel')"
          [ngClass]="{
            'pi-eye': !showPassword,
            'pi-eye-slash': showPassword
          }"
          (click)="toggleShowPassword()"
        ></i>
        <input
          pInputText
          class="login__form-field-input"
          [formControl]="passwordControl"
          [placeholder]="t('passwordField.placeholder')"
          [type]="showPassword ? 'text' : 'password'"
        />
      </span>

      <app-form-field-help [control]="passwordControl">
        <ng-container *ngIf="passwordControl.errors as passwordErrors" form-field-help-errors>
          <ng-container *ngIf="passwordErrors.required">{{ t('passwordField.requiredError') }}</ng-container>
        </ng-container>
      </app-form-field-help>
    </label>

    <div class="login__form-actions">
      <label class="login__form-actions-checkbox">
        <p-checkbox [binary]="true" [formControl]="form.controls.acceptTermsAndPrivacyPolicy"></p-checkbox>
        <span [innerHTML]="t('acceptTermsAndPrivacyPolicy')"> </span>
      </label>

      <a routerLink="/auth/forgot-password" tabindex="0">{{ t('forgotPassword') }}</a>
    </div>

    <p-messages [closable]="false"></p-messages>
  </form>

  <div class="login__actions">
    <button
      *ngrxLet="loading$ as loading"
      pButton
      class="login__actions-button"
      icon="pi pi-user"
      [attr.aria-busy]="loading"
      [disabled]="form.invalid || loading"
      [label]="t('signInButtonLabel')"
      [loading]="loading"
      (click)="login()"
    ></button>
  </div>
</ng-container>
