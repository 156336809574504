import { DownloadCSVInput } from './interfaces';

export const downloadCSV = ({ data, filename }: DownloadCSVInput): void => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
};
