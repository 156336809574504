import { Input, stringify } from 'csv-stringify/browser/esm/sync';
import moment from 'moment-timezone';

interface CreateCSVInput {
  columns: Record<string, string>;
  rows: Input;
}

export const createCSV = ({ rows, columns }: CreateCSVInput) =>
  stringify(rows, {
    header: true,
    columns,
    cast: {
      date: (value) => value.toISOString(),
      object: (value) => (moment.isMoment(value) ? value.toISOString() : JSON.stringify(value)),
    },
  });
