import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {AutomationResponseSchema} from '../../generic/automation-response/automation-response-schema';

import {AutomationResponseCreateAutomationError} from './automation-response-create-automation-error';

export class AutomationResponseCreateAutomationSchema extends AutomationResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[AutomationResponseCreateAutomationSchema.error] = SchemaField.string(AutomationResponseSchema.Defaults.error)
      .optional()
      .valid(...Object.values(AutomationResponseCreateAutomationError));

    return parameters;
  }
}
