import {Hangup} from '../../../voice-response-command/hangup/hangup';
import {Say} from '../../../voice-response-command/say/say';
import {SaySchema} from '../../../voice-response-command/say/say-schema';
import {CallListOfferConfig} from '../offer-config/call-list-offer-config/call-list-offer-config';

///////////////////////////////////////////////////////////////////////////////////////////////
// Constants
///////////////////////////////////////////////////////////////////////////////////////////////
const NO_COUNSELOR_IS_AVAILABLE_ENG = 'Hi, thanks for calling, all counselors are currently assisting other clients. Please call back.';
const NO_COUNSELOR_IS_AVAILABLE_ES = 'Hola, gracias por llamar, todos los consejeros están ayudando a otros clientes. Por favor devolver la llamada.';

///////////////////////////////////////////////////////////////////////////////////////////////
// Default commands if no user available to dial
///////////////////////////////////////////////////////////////////////////////////////////////

// English
const sayEnglish = {} as any;
sayEnglish[SaySchema.message] = NO_COUNSELOR_IS_AVAILABLE_ENG;
sayEnglish[SaySchema.loop] = 1;
sayEnglish[SaySchema.language] = 'en';
sayEnglish[SaySchema.voice] = SaySchema.Defaults.voice;

// Spanish
const saySpanish = {} as any;
saySpanish[SaySchema.message] = NO_COUNSELOR_IS_AVAILABLE_ES;
saySpanish[SaySchema.loop] = 1;
saySpanish[SaySchema.language] = 'es';
saySpanish[SaySchema.voice] = SaySchema.Defaults.voice;

// Commands to execute to modify incoming call, when no user left to dial
export const defaultOnNoUserLeftToDialModIncCallCommands: (import('../../../voice-response-command/say/say').Say | import('../../../voice-response-command/hangup/hangup').Hangup)[] = [
  new Say(sayEnglish),
  new Say(saySpanish),
  new Hangup({}),
];

export const defaultCallListOfferConfig: CallListOfferConfig = CallListOfferConfig.deserialize({
  offerChannelConfigPhoneCall: {
    machineDetection: {
      hangupOnMachine: true,
    },
  },
});
