import { OfferConfigRingTimeEditorOutput } from './interfaces';
import { PresetValues } from './preset-values';

export const defaultOfferConfigRingTimeEditorOutput: OfferConfigRingTimeEditorOutput = {
  ringTimeoutSeconds: 60,
  hangUpOnVoiceMail: true,
  preset: PresetValues.custom,
};

export const answerQuicklyRingTimeEditorOutput: OfferConfigRingTimeEditorOutput = {
  ringTimeoutSeconds: 15,
  hangUpOnVoiceMail: true,
  preset: PresetValues.answerQuickly,
};
