import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { combineLatest, forkJoin, map, Observable, of, shareReplay, switchMap, take, withLatestFrom } from 'rxjs';

import { CallLog, computeAllEntityStats, EntityStats, EntityStatsType, EventData, OrgData } from '@pwp-common';

import { UserStatsData } from '../../components/analytics/admin-reports/table-rows/interfaces';
import { MakeTableDataInput } from '../../components/analytics/admin-reports/table-rows/make-table-data/interfaces';
import { DateRangeSelectOutput } from '../../components/core/date-range-select/common/date-range-select-output';
import { EntityStatsService } from '../analytics/entity-stats/entity-stats.service';
import { CallListService } from '../call/call-list/call-list.service';
import { CallLogService } from '../call/call-log/call-log.service';
import { EventsService } from '../event/events/events.service';
import { OrgDataService } from '../orgs/org-data/org-data.service';
import { AllDataUserService } from '../user/all-data-user/all-data-user.service';
import { UserDataService } from '../user/user-data/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private readonly callLists$ = this.callListService.getDocs().pipe(
    map((callListMap) => Array.from(callListMap.values())),
    take(1),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly orgData$ = this.orgDataService.getOrgData().pipe(take(1), shareReplay({ bufferSize: 1, refCount: true }));

  private readonly eventTypes$ = this.orgData$.pipe(map((orgData) => orgData.getEventTypes()));

  constructor(
    private allDataUserService: AllDataUserService,
    private callListService: CallListService,
    private callLogService: CallLogService,
    private entityStatsService: EntityStatsService,
    private eventsService: EventsService,
    private orgDataService: OrgDataService,
    private userDataService: UserDataService,
  ) {}

  private getCallData({ start, end }: DateRangeSelectOutput): Observable<CallLog[]> {
    return this.callLogService.getCallsWithStartInRange(moment(start), moment(end));
  }

  private getEventData(orgData: OrgData, { start, end }: DateRangeSelectOutput): Observable<EventData[]> {
    const eventTypes = orgData.getEventTypes().map((event) => event.getInternalName());

    return this.eventsService.getEventsWithStartInRange(moment(start), moment(end), eventTypes);
  }

  private getUsersStatsData(usersStats: EntityStats[]): Observable<UserStatsData[]> {
    return forkJoin(
      usersStats.map((userStats) =>
        this.userDataService.getDoc(userStats.getId()).pipe(
          map((userData) => ({
            userStats,
            userData,
          })),
        ),
      ),
      // @Todo: Remove this check.
      // This condition should never happen and can be removed once we assure user stats are deleted along with user data.
    ).pipe(map((usersStatsData) => usersStatsData.filter(({ userData }) => !isNil(userData))));
  }

  private createMakeTableDataInputFromStatsMap(statsMap: Map<string, EntityStats>): Observable<MakeTableDataInput> {
    const usersStats = [...statsMap.values()].filter((stats) => stats.getType() === EntityStatsType.oneUser);

    return this.getUsersStatsData(usersStats).pipe(
      withLatestFrom(this.callLists$, this.eventTypes$),
      map(([usersStatsData, callLists, eventTypes]) => ({
        entityStats: statsMap.get(EntityStatsType.org),
        usersStatsData,
        callLists,
        eventTypes,
      })),
    );
  }

  public getDataForReport({ start, end }: Partial<DateRangeSelectOutput>): Observable<MakeTableDataInput> {
    if (!start || !end) {
      return combineLatest({
        entityStats: this.entityStatsService.getDoc(EntityStatsType.org),
        usersStatsData: this.entityStatsService
          .getDocsArray({ query: [{ fieldPath: 'type', opStr: '==', value: EntityStatsType.oneUser }] })
          .pipe(switchMap((usersStats) => this.getUsersStatsData(usersStats))),
        callLists: this.callLists$,
        eventTypes: this.eventTypes$,
      });
    }

    return this.orgData$.pipe(
      switchMap((orgData) =>
        forkJoin([
          this.getCallData({ start, end }),
          this.getEventData(orgData, { start, end }),
          this.allDataUserService.getDocs(),
          of(orgData),
        ]),
      ),
      map(([callData, events, allDataUser, orgData]) => computeAllEntityStats(callData, events, allDataUser, orgData.getTimezone())),
      switchMap((statsMap) => this.createMakeTableDataInputFromStatsMap(statsMap)),
    );
  }
}
