import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { FormSubmissionComponent } from '../form-submission/form-submission.component';

@Component({
  selector: 'app-form-submission-dialog',
  standalone: true,
  imports: [FormSubmissionComponent, TranslocoModule],
  templateUrl: './form-submission-dialog.component.html',
  styleUrls: ['./form-submission-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubmissionDialogComponent {
  private readonly config = inject(DynamicDialogConfig);

  private readonly dialogRef = inject(DynamicDialogRef);

  private readonly translocoService = inject(TranslocoService);

  public readonly sessionId = this.config.data.sessionId;

  public closeRefWithSuccess(): void {
    this.dialogRef.close({
      message: {
        severity: 'success',
        summary: this.translocoService.translate('form-submission-dialog.formSubmissionSuccess.summary'),
        detail: this.translocoService.translate('form-submission-dialog.formSubmissionSuccess.detail'),
      },
    });
  }
}
