import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import { AllDataUser, DBDocSchema, getAllDataUserMapFromMaps, Roles, UserData, UserPrivateData } from '@pwp-common';

import { commitBatchObservable } from '../../generic/batch/commit-batch-observable';
import { UploadMode } from '../../generic/interfaces';
import { observableTakeOne } from '../../generic/take-one';
import { AuthService } from '../auth/auth.service';
import { RolesService } from '../roles/roles.service';
import { UserDataService } from '../user-data/user-data.service';
import { UserPrivateDataService } from '../user-private-data/user-private-data.service';

@Injectable({
  providedIn: 'root',
})
export class AllDataUserService {
  ////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private rolesService: RolesService,
    private userPrivateDataService: UserPrivateDataService,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////////
  // Get Logged In Data
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Get AllDataUser associated to one user.
   */
  public getLoggedInData(takeOne?: boolean): Observable<AllDataUser> {
    const observable = this.authService.getUserId().pipe(
      switchMap((userId) =>
        forkJoin([this.userDataService.getDoc(userId), this.rolesService.getDoc(userId), this.userPrivateDataService.getDoc(userId)]),
      ),
      map((values): AllDataUser => new AllDataUser(values[0], values[1], values[2])),
      finalize(() => console.log('AllDataUserService.getDoc: Complete')),
    );
    return observableTakeOne(observable, takeOne);
  }
  ////////////////////////////////////////////////////////////////////////////////////
  // Get One Docs
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Get AllDataUser associated to one user.
   */
  public getDoc(userId: string): Observable<AllDataUser | undefined> {
    const observable = forkJoin([
      this.userDataService.getDoc(userId),
      this.rolesService.getDoc(userId),
      this.userPrivateDataService.getDoc(userId),
    ]).pipe(
      map((values): AllDataUser => {
        try {
          const allDataUser = new AllDataUser(values[0], values[1], values[2]);
          return allDataUser;
        } catch (error) {
          console.log('AllDataUserService.getDoc: Error making user. This is likely normal. The user was probably deleted.');
          console.log(error);
          return undefined;
        }
      }),
      finalize(() => console.log('AllDataUserService.getDoc: Complete')),
    );
    return observableTakeOne(observable, true);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Get One Docs
  ////////////////////////////////////////////////////////////////////////////////////

  public getDocsWithIds(docIds: string[], includeUserPrivate: boolean): Observable<Map<string, AllDataUser>> {
    const observables: [Observable<Map<string, UserData>>, Observable<Map<string, Roles>>, Observable<Map<string, UserPrivateData>>] = [
      this.userDataService.getDocsWithIds(docIds),
      this.rolesService.getDocsWithIds(docIds),
      includeUserPrivate === true ? this.userPrivateDataService.getDocsWithIds(docIds) : of(new Map()),
    ];
    const observable = forkJoin(observables).pipe(
      map((values) => {
        const result = new Map<string, AllDataUser>();

        for (const userId of values[0].keys()) {
          const allDataUser = new AllDataUser(values[0].get(userId), values[1].get(userId), values[2].get(userId));
          result.set(userId, allDataUser);
        }

        return result;
      }),
      catchError((err, caught) => {
        console.error(`AllDataUserService.getDocsWithIds: Error. docIds=${docIds.join(',')}`);
        console.error(err);
        console.error(caught);
        throw err;
      }),
      finalize(() => console.log(`AllDataUserService.getDocsWithIds: Complete. docIds=${docIds.join(',')}`)),
    );
    return observableTakeOne(observable, true);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Get All Docs
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Get a map specifying all data for each user.
   */
  public getDocs(includeUserPrivate = true): Observable<Map<string, AllDataUser>> {
    const observable = forkJoin([
      this.userDataService.getDocs(),
      this.rolesService.getDocs(),
      includeUserPrivate === true ? this.userPrivateDataService.getDocs() : of(new Map()),
    ]).pipe(
      map((values): Map<string, AllDataUser> => getAllDataUserMapFromMaps(values[0], values[1], values[2])),
      finalize(() => console.log('AllDataUserService.getData: Complete')),
    );
    return observableTakeOne(observable, true);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Upload
  ////////////////////////////////////////////////////////////////////////////////////

  public async upload(allDataUser: AllDataUser): Promise<void> {
    const batch = firebase.firestore().batch();

    let mode: UploadMode = 'update';
    const loggedInUserId = await this.authService.getUserId().toPromise();
    if (allDataUser.getUserId() === DBDocSchema.GenericDefaults.id) {
      mode = 'create';
      allDataUser.userData.setId(loggedInUserId);
      allDataUser.userPrivateData.setId(loggedInUserId);
      allDataUser.roles.setId(loggedInUserId);
      // Only pwpAdmin can create, so make admin by default
      allDataUser.roles.setRoles(['orgAdmin']);
      console.log('Creating user', { userId: loggedInUserId, allDataUser });
    }
    const observables = [
      this.userDataService.uploadInBatch({ obj: allDataUser.userData, mode, generateId: false, batch }),
      this.userPrivateDataService.uploadInBatch({ obj: allDataUser.userPrivateData, mode, generateId: false, batch }),
    ];

    if (allDataUser.getUserId() !== loggedInUserId || mode === 'create') {
      observables.push(this.rolesService.uploadInBatch({ obj: allDataUser.roles, mode, generateId: false, batch }));
    }

    return forkJoin(observables)
      .pipe(
        switchMap((_) => commitBatchObservable(of(batch), 'AllDataUserService.upload')),
        finalize(() => console.log('AllDataUserService.upload: Complete')),
      )
      .toPromise();
  }
}
