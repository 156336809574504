import {Request} from '../../../../generic/v2/request/request';
import {AutomationRequestNameType} from '../../../automation-request-name';

export abstract class AutomationRequest extends Request<AutomationRequestNameType> {
  public getLogMetadata(): Record<string, unknown> {
    return {
      type: this.getType(),
    };
  }
}
