import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { AllDataEvent, AllDataUser, AssignedUserType, EventData, EventDataSchema, makeAllDataEventMap } from '@pwp-common';

import { DBQuery } from '../../generic/interfaces';
import { AllDataUserService } from '../../user/all-data-user/all-data-user.service';
import { EventRequestsService } from '../event-requests/event-requests.service';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class AllDataEventService {
  constructor(
    private allDataUserService: AllDataUserService,
    private eventService: EventsService,
    private eventRequestsService: EventRequestsService,
  ) {}

  ///////////////////////////////////////////////////////////////////////
  // Get Docs For
  ///////////////////////////////////////////////////////////////////////

  public getDocsFor(events: EventData[], includeUserPrivate: boolean): Observable<Map<string, AllDataEvent>> {
    // Step 1: Form the set of all associated userIds
    const userIds: Set<string> = new Set();
    for (const event of events) {
      userIds.add(event.getAssignedUserId());
      userIds.add(event.getAssignedBackupUserId());
    }
    userIds.delete(undefined);
    userIds.delete(EventDataSchema.Defaults.assignedUserId);
    userIds.delete(EventDataSchema.Defaults.assignedBackupUserId);

    // Step 2: Get all associated user data
    const observable: Observable<Map<string, AllDataEvent>> = this.allDataUserService
      .getDocsWithIds(Array.from(userIds), includeUserPrivate)
      .pipe(
        map((allDataUserMap, _) => {
          const result = new Map<string, AllDataEvent>();

          for (const event of events) {
            const allDataUserMapForEvent = new Map<string, AllDataUser>();
            const assignedUsers = [event.getAssignedUserId(), event.getAssignedBackupUserId()].filter((z) => z !== undefined);

            for (const user of assignedUsers) {
              allDataUserMapForEvent.set(user, allDataUserMap.get(user));
            }
            const allDataEvent = new AllDataEvent(event, allDataUserMapForEvent, new Map());
            result.set(event.getId(), allDataEvent);
          }

          return result;
        }),
      );

    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Open Requests
  ///////////////////////////////////////////////////////////////////////

  public getByRequest(params?: { createStart: Date; createEnd: Date }) {
    let requestsObservable = this.eventRequestsService.getOpenRequests(true).pipe();
    if (!isNil(params)) {
      requestsObservable = this.eventRequestsService
        .getRequestsCreatedInRange(params.createStart, params.createEnd, true)
        .pipe(shareReplay(1));
    }
    const eventsObservable = requestsObservable.pipe(
      tap((eventRequests) => {
        console.log(eventRequests);
      }),
      switchMap((eventRequests) => {
        const eventIds = eventRequests.map((z) => z.getEventId());
        console.log(eventIds);
        return this.eventService.getDocsWithIds(eventIds);
      }),
      shareReplay(1),
      take(1),
    );
    const usersObservable = forkJoin([eventsObservable, requestsObservable]).pipe(
      switchMap(([eventsMap, requests]) => {
        const events = Array.from(eventsMap.values());
        const userIds = new Set(events.flatMap((z) => z.getUsers([AssignedUserType.primary, AssignedUserType.backup])));
        for (const request of requests) {
          userIds.add(request.getRequestedAssignedUserId());
        }
        userIds.delete(undefined);
        userIds.delete(EventDataSchema.Defaults.assignedUserId);
        userIds.delete(EventDataSchema.Defaults.assignedBackupUserId);
        console.log(userIds);
        return this.allDataUserService.getDocsWithIds(Array.from(userIds), false);
      }),
    );

    return forkJoin([requestsObservable, eventsObservable, usersObservable]).pipe(
      map((result) => {
        const events = Array.from(result[1].values());
        const allDataEventMap = makeAllDataEventMap(events, result[2], result[0]);
        console.log(allDataEventMap);
        return Array.from(allDataEventMap.values());
      }),
      take(1),
    );
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Docs
  ///////////////////////////////////////////////////////////////////////

  public getAllDocs(query: DBQuery[] = [], includeUserPrivate: boolean, takeOne = true): Observable<Map<string, AllDataEvent>> {
    return this.eventService
      .getDocs(query, undefined, undefined, takeOne)
      .pipe(switchMap((eventDataMap, _) => this.getDocsFor(Array.from(eventDataMap.values()), includeUserPrivate)));
  }

  ///////////////////////////////////////////////////////////////////////
  // Delete Event
  ///////////////////////////////////////////////////////////////////////

  public deleteEvent(eventData: EventData): any {
    // // Delete the event, and any requests for this event.
    // console.log(`Deleting event with id: ${eventData.getId()}`);
    // // @Todo: This function should also delete event requests for this eventId.
    // const promise = this.refFirestoreDocumentReference(eventData.getId()).delete();
    // promise.then(_ => {
    //   console.log('deleteEvent: Successful');
    // })
    // .catch(err => {
    //   console.log('deleteEvent: Error!');
    //   console.log(err);
    //   throw err;
    // })
    // .finally(() => {
    //   console.log('deleteEvent: Completed.');
    // });
    // return promise;
  }
}
