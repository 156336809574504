import { CallLogDisplay, ConversationLog, ConversationLogDisplay, DisplayableCommunication } from '@pwp-common';

import { CreateDisplayableCommunicationInput } from './interfaces';

export const createDisplayableCommunication = ({
  communicationLog,
  allUserDataMap,
  callListMap,
  orgData,
}: CreateDisplayableCommunicationInput): DisplayableCommunication => {
  if (communicationLog instanceof ConversationLog) {
    return new ConversationLogDisplay(communicationLog, allUserDataMap, orgData.getTimezone(), new Map());
  }

  return new CallLogDisplay({
    callLog: communicationLog,
    callListMap,
    allUserDataMap,
    ivrsMap: new Map(),
    timezone: orgData.getTimezone(),
  });
};
