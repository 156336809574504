import type {ValidationResult} from 'joi';
import {cloneDeep} from 'lodash';

import {Automation} from '../../../../../objects/automation/automation/generic/automation/automation';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {AutomationRequestName} from '../../../automation-request-name';
import {AutomationRequest} from '../../generic/automation-request/automation-request';
import {AutomationRequestSchema} from '../../generic/automation-request/automation-request-schema';

import {AutomationRequestCreateAutomationConstructor} from './automation-request-create-automation-constructor';
import {AutomationRequestCreateAutomationSchema} from './automation-request-create-automation-schema';

export class AutomationRequestCreateAutomation extends AutomationRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected automation!: Automation;

  protected automationId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AutomationRequestCreateAutomationConstructor) {
    super({
      ...parameters,
      [AutomationRequestSchema.type]: AutomationRequestName.createAutomation,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): AutomationRequestCreateAutomation {
    return new AutomationRequestCreateAutomation(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AutomationRequestCreateAutomationSchema {
    return new AutomationRequestCreateAutomationSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AutomationRequestCreateAutomation.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAutomation(): Automation {
    return cloneDeep(this.automation);
  }

  public getAutomationId(): string {
    return cloneDeep(this.automationId);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Log metadata
  /////////////////////////////////////////////////////////////////////////////

  public getLogMetadata(): Record<string, unknown> {
    return {
      ...super.getLogMetadata(),
      automation: this.getAutomation(),
    };
  }
}
