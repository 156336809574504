import { createCSV } from '../../../../../../common/files/create-csv/create-csv';

import { coreColumnsPrefix } from './constants';
import { generateColumnId } from './generate-column-id';
import { getQuestionColumns } from './get-question-columns';
import { CreateFormSubmissionCSVDataInput } from './interfaces';
import { parseColumns } from './parse-columns';

export const createFormSubmissionCSV = ({ data, tableColumns }: CreateFormSubmissionCSVDataInput): string => {
  const initialColumns = Object.fromEntries(tableColumns.map(({ field, label }) => [generateColumnId(field, coreColumnsPrefix), label]));
  const initialRows: Record<string, unknown>[] = [];

  const { columns, rows } = data.reduce(
    (acc, submissionRow) => {
      const { surveyModel, ...dataColumns } = submissionRow;
      const formColumnsPrefix = dataColumns.form;

      const row = {
        ...parseColumns(dataColumns, coreColumnsPrefix),
        ...parseColumns(surveyModel.getAllValues(), formColumnsPrefix),
      };

      return {
        columns: { ...acc.columns, ...getQuestionColumns(submissionRow, formColumnsPrefix) },
        rows: [...acc.rows, row],
      };
    },
    { columns: initialColumns, rows: initialRows },
  );

  return createCSV({ columns, rows });
};
